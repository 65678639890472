import { useState } from "react";
import { Box, Grid } from "@mui/material";
import dayjs from "dayjs";
import { useLazyIsScheduleValidQuery, useHasRecipientsMutation } from './surveyTemplateWizardSlice';
import WizardButtonGroup from "../../Common/Buttons/WizardButtonGroup";
import { RecurrenceEnum, MonthlyRecurrenceEnum } from "../../../utility/enums";
import SchedulingForm from "./StepFourComponents/SchedulingForm";
import DistributionForm from "./StepFourComponents/DistributionForm";
import SurveyTitleHeader from "./Common/SurveyTitleHeader";
import ConfirmationModal from "../../Common/ConfirmationModal";

export default function StepFour({ title, campaignId, campaignStartDate, campaignEndDate, distributionSchedule, setDistributionSchedule, prevStep, nextStep, isSaving }) {
    const [distributionForm, setDistributionForm] = useState({
        schoolBuildingIds: distributionSchedule?.schoolBuildingIds || [],
        roleIds: distributionSchedule?.roleIds || [],
        minutesToComplete: distributionSchedule?.minutesToComplete || '',
        sampleSizeId: distributionSchedule?.sampleSizeId || null,
        rotationId: distributionSchedule?.rotationId || null,
    });

    const [schedulingForm, setSchedulingForm] = useState({
        cronSchedule: distributionSchedule?.cronSchedule || null,
        recurrencePattern: null,
        monthType: null,
        dayOfMonth: null,
        weekOfMonth: null,
        selectedWeekdays: [],
        startDate: distributionSchedule?.startDate || (dayjs(campaignStartDate) > dayjs() ? dayjs(campaignStartDate) : dayjs()),
        endDate: distributionSchedule?.endDate || dayjs(campaignEndDate)
    });

    const [stepErrors, setStepErrors] = useState({});
    const [showingConfirmModal, setShowingConfirmModal] = useState(false);
    const [isScheduleValid] = useLazyIsScheduleValidQuery();
    const [hasRecipients] = useHasRecipientsMutation();

    const areFormsValidAsync = async () => {
        const errors = {};

        // Distribution Form Validation
        errors['schoolBuildings'] = distributionForm.schoolBuildingIds.length === 0 ? "At least one school building required" : null;
        errors['roles'] = distributionForm.roleIds.length === 0 ? "At least one role required" : null;
        errors['minsToComplete'] = (
            !distributionForm.minutesToComplete ||
            parseInt(distributionForm.minutesToComplete) === 0 ||
            (typeof distributionForm.minutesToComplete === 'string' && distributionForm.minutesToComplete.startsWith('.')))
            ? "Minutes required" : null;
        errors['sampleSize'] = !distributionForm.sampleSizeId ? "Sample Size required" : null;
        errors['rotation'] = !distributionForm.rotationId ? "Rotation required" : null;

        // Scheduling Form Validation
        errors['recurrencePattern'] = !schedulingForm.recurrencePattern ? "Recurrence pattern required" : null;
        errors['monthType'] = schedulingForm.recurrencePattern?.id === RecurrenceEnum.MONTHLY && !schedulingForm.monthType ? "Scheduled recurrence required" : null;
        errors['dayOfMonth'] = schedulingForm.monthType?.id === MonthlyRecurrenceEnum.ON_A_DAY && !schedulingForm.dayOfMonth ? "Day of month required" : null;
        errors['dateRange'] = !schedulingForm.startDate || !schedulingForm.endDate ? "Date range required" : null
        if (schedulingForm.cronSchedule && schedulingForm.startDate && schedulingForm.endDate) {
            const args = {
                cronSchedule: schedulingForm.cronSchedule,
                startDate: schedulingForm.startDate.toISOString(),
                endDate: schedulingForm.endDate.toISOString()
            }
            const response = await isScheduleValid(args, true).unwrap();

            errors['dateRange'] = !response.isValid ? "No surveys would occur in the date range selected" : null;
        }

        if (schedulingForm.recurrencePattern?.id === RecurrenceEnum.WEEKLY ||
            schedulingForm.recurrencePattern?.id === RecurrenceEnum.BI_WEEKLY ||
            schedulingForm.monthType?.id === MonthlyRecurrenceEnum.ON_A_FIRST_WEEKDAY)
            errors['selectedWeekdays'] = schedulingForm.selectedWeekdays.length === 0 ? "At least one day required" : null;
        else
            errors['selectedWeekdays'] = null;

        if (schedulingForm.startDate && schedulingForm.endDate && schedulingForm.startDate > schedulingForm.endDate)
            errors['endDate'] = "End date must be after start date";

        setStepErrors(errors);

        for (let prop in errors) // survey recipients aren't truely required
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    };

    const validateRecipients = async () => {
        const recipientResponse = await hasRecipients({
            schoolBuildingIds: distributionForm.schoolBuildingIds,
            roleIds: distributionForm.roleIds
        }, false).unwrap();

        return recipientResponse.isValid;
    }

    const handleConfirmation = () => {
        setShowingConfirmModal(false);
        commit();
    }

    const handleNext = () => {
        areFormsValidAsync().then((isFormValid) => {
            if (!isFormValid) {
                return;
            } else {
                validateRecipients()
                    .then(isValid => {
                        if (!isValid)
                            setShowingConfirmModal(true);
                        else
                            commit();
                    })
            }
        });
    };

    const commit = () => {
        const updatedDistributionSchedule = {
            ...distributionSchedule,
            ...distributionForm,
            ...schedulingForm,
        };

        setDistributionSchedule(updatedDistributionSchedule);
        nextStep();
    }

    const handlePrevStep = () => {
        setDistributionSchedule(prevState => (
            {
                ...prevState,
                ...distributionForm,
                ...schedulingForm,
            }
        ))
        prevStep();
    };

    return (
        <Box
            sx={{
                width: '100%',
                padding: '0% 3% 0% 3%',
                maxWidth: '1100px',
            }}
        >
            <SurveyTitleHeader title={title} />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid spacing={'25px'} container>
                    <Grid item xs={12} md={6}>
                        <DistributionForm
                            campaignId={campaignId}
                            form={distributionForm}
                            setForm={setDistributionForm}
                            formErrors={stepErrors}
                            setFormErrors={setStepErrors}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <SchedulingForm
                            form={schedulingForm}
                            setForm={setSchedulingForm}
                            formErrors={stepErrors}
                            setFormErrors={setStepErrors}
                            campaignId={campaignId}
                            campaignStartDate={campaignStartDate}
                            campaignEndDate={campaignEndDate}
                        />
                    </Grid>
                </Grid>
            </Box>
            <WizardButtonGroup
                backClick={handlePrevStep}
                forwardClick={() => handleNext()}
                forwardText="Save & Continue"
                isLoading={isSaving}
            />
            <ConfirmationModal
                isOpen={showingConfirmModal}
                close={() => setShowingConfirmModal(false)}
                onConfirm={handleConfirmation}
                title="Survey Cannot be Scheduled"
                message="There are no users in this role for the selected school. Your survey can be saved, but it will not send to users. Are you sure you want to continue? "
                saveBtnText="Confirm"
                cancelBtnText="Cancel"
            />
        </Box>
    );
}