import { useState, useEffect } from 'react';
import { toast } from "react-toastify";
import { Dialog, DialogTitle, DialogContent, TextField, Stack, IconButton, InputAdornment, Link, Box } from '@mui/material';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { REGEX } from '../../utility/regex';
import { useUpdateProfileMutation } from './manageProfileSlice';
import PillarXpLogo from '../../images/PillarXP-Logo-Alt.svg';
import ContactSchoolDistrictModal from '../Common/ContactSchoolDistrictModal';
import DialogActionButtons from '../Common/Buttons/DialogActionButtons';
import { useLazyGetSchoolsWithBuildingsQuery } from '../../app/slices/schoolsSlice';
import GroupedSchoolBuildingAutocomplete from '../Common/FormElements/GroupedSchoolBuildingAutocomplete';
import PasswordRequirements from '../Common/FormElements/PasswordRequirements';

export default function ManageProfileDialog({ open, onClose, user }) {
    const [isLoading, setIsLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [verifyPassword, setVerifyPassword] = useState('');
    const [isVerifyPasswordVisible, setIsVerifyPasswordVisible] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [contactSchoolDistrict, setContactSchoolDistrict] = useState(false);
    const [userSchoolBuildingIds, setUserSchoolBuildingIds] = useState([]);

    const [trigger, { data: schools }] = useLazyGetSchoolsWithBuildingsQuery();
    const [updateProfile] = useUpdateProfileMutation();

    // Fetch schools on open
    useEffect(() => {
        if (open) {
            trigger();
        }

        if (user && user.schools) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmailAddress(user.emailAddress);

            const schoolBuildingIds = user.schools.flatMap(school =>
                school.schoolBuildings?.map(building => building.id) || []
            );
            setUserSchoolBuildingIds(schoolBuildingIds);
        }
    }, [open, user, trigger]);

    function validate(e) {
        setFormErrors(prev => ({
            ...prev,
            [e.target.id]: !e.target.value.trim() ? 'Field is required' : null
        }));
    }

    function isFormValid() {
        const errors = {};

        errors['first-name'] = !firstName?.trim() ? 'Field is required' : null;
        errors['last-name'] = !lastName?.trim() ? 'Field is required' : null;
        errors['email-address'] = !emailAddress?.trim() ? 'Field is required' : null;

        if (!errors['email-address'])
            errors['email-address'] = !emailAddress.match(REGEX.EMAIL) ? 'Email must be properly formatted' : null;

        if (!errors['new-password'])
            errors['new-password'] = !!newPassword && !newPassword.match(REGEX.PASSWORD) ? 'Password must be a minimum of 12 characters, include at least one letter (a-z), include at least one number (0-9)' : null;

        if (!errors['verify-password']) {
            errors['verify-password'] = verifyPassword !== newPassword ? 'Passwords must match' : null;
            errors['new-password'] = verifyPassword !== newPassword ? 'Passwords must match' : null;
        }

        setFormErrors(errors);

        for (let prop in errors)
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    }

    function handleClose() {
        onClose();
        setFormErrors({});
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setEmailAddress(user.emailAddress);
        setNewPassword('');
        setVerifyPassword('');
        setUserSchoolBuildingIds([]);
    }

    function submit() {
        if (!isFormValid()) {
            toast.error('Failed to update user profile');
            return;
        }

        const dto = {
            id: user.id,
            schoolBuildingIds: userSchoolBuildingIds,
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            newPassword: newPassword
        }

        setIsLoading(true);

        updateProfile(dto)
            .then(response => {
                setIsLoading(false);

                if (response.error)
                    toast.error(response.error);

                toast.success('Successfully updated user profile.');

                onClose();
            })
            .catch(e => {
                setIsLoading(false);
                toast.error(e);
            });
    }

    return user && (
        <>
            <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Stack spacing={2} alignItems="center">
                        <img
                            src={PillarXpLogo}
                            alt='logo'
                            style={{ width: '15em', marginTop: '1em' }}
                        />
                        <Box sx={{ alignSelf: 'start' }}>
                            Profile Update
                        </Box>
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: -8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ paddingTop: '0.3em', mb: -2 }}>
                        <Box sx={{ display: 'inline-flex', gap: '20px' }}>
                            <TextField
                                id="update-profile-first-name"
                                label="First Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="first-name"
                                autoComplete='given-name'
                                required
                                fullWidth
                                value={firstName}
                                onBlur={validate}
                                error={!!formErrors['first-name']}
                                helperText={formErrors['first-name']}
                                onChange={e => setFirstName(e.target.value)}
                            />
                            <TextField
                                id="update-profile-last-name"
                                label="Last Name"
                                variant="outlined"
                                size="small"
                                type="text"
                                name="last-name"
                                autoComplete='family-name'
                                required
                                fullWidth
                                value={lastName}
                                onBlur={validate}
                                error={!!formErrors['last-name']}
                                helperText={formErrors['last-name']}
                                onChange={e => setLastName(e.target.value)}
                            />
                        </Box>
                        <TextField
                            id="update-profile-email-address"
                            label="Email Address"
                            variant="outlined"
                            size="small"
                            type='email'
                            autoComplete='email'
                            required
                            fullWidth
                            value={emailAddress}
                            onBlur={validate}
                            error={!!formErrors['email-address']}
                            helperText={formErrors['email-address']}
                            onChange={e => setEmailAddress(e.target.value)}
                        />
                        <TextField
                            fullWidth
                            id="update-profile-new-pw"
                            label='New Password'
                            variant='outlined'
                            size='small'
                            value={newPassword}
                            required={!!newPassword}
                            onChange={(e => setNewPassword(e.target.value))}
                            error={!!formErrors['new-password']}
                            helperText={formErrors['new-password']}
                            type={isNewPasswordVisible ? 'text' : 'password'}
                            autoComplete='new-password'
                            name='new-pw'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
                                            edge='end'
                                            title={isNewPasswordVisible ? 'Hide Password' : 'Show Password'}
                                        >
                                            {isNewPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {newPassword && (
                            <PasswordRequirements password={newPassword} />
                        )}
                        <TextField
                            fullWidth
                            id="update-profile-verify-pw"
                            label='Verify New Password'
                            variant='outlined'
                            size='small'
                            value={verifyPassword}
                            required={!!newPassword}
                            onChange={(e => setVerifyPassword(e.target.value))}
                            error={!!formErrors['verify-password']}
                            helperText={formErrors['verify-password']}
                            type={isVerifyPasswordVisible ? 'text' : 'password'}
                            autoComplete='new-password'
                            name='verify-new-pw'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={() => setIsVerifyPasswordVisible(!isVerifyPasswordVisible)}
                                            edge='end'
                                            title={isVerifyPasswordVisible ? 'Hide Password' : 'Show Password'}
                                        >
                                            {isVerifyPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <GroupedSchoolBuildingAutocomplete
                            fullWidth
                            schools={schools}
                            schoolBuildingIds={userSchoolBuildingIds}
                            setSchoolBuildingIds={setUserSchoolBuildingIds}
                            tagLimit={2}
                            allowSchoolGroupSelect={false}
                        />
                        <Box>
                            <Link
                                onClick={() => setContactSchoolDistrict(true)}
                                color='secondary'
                                sx={{ cursor: 'pointer' }}
                            >
                                Contact School
                            </Link>
                        </Box>
                    </Stack>
                </DialogContent >
                <DialogActionButtons
                    fullWidth
                    submitText='Save'
                    onSubmit={submit}
                    closeText='Cancel'
                    onClose={handleClose}
                    isLoading={isLoading}
                />
            </Dialog >
            <ContactSchoolDistrictModal
                open={contactSchoolDistrict}
                onClose={() => setContactSchoolDistrict(false)}
                districtId={user.schoolDistrictId} />
        </>
    )
}