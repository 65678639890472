import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, IconButton, Switch, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import DialogActionButtons from '../Buttons/DialogActionButtons';
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import { useGetNotificationSettingsQuery, usePutNotificationSettingsMutation } from '../../../app/slices/notificationSettingsSlice';
import { RoleEnum } from '../../../utility/enums';
import { toast } from 'react-toastify';

const roleAccess = {
    approvalRequests: (role => role.roleId === RoleEnum.DISTRICT_ADMIN || role.roleId === RoleEnum.INTEGRATOR),
    campaigns: (role => [RoleEnum.DISTRICT_ADMIN, RoleEnum.INTEGRATOR, RoleEnum.SCHOOL_ADMIN, RoleEnum.FACULTY].includes(role.roleId))
}

export default function NotificationSettingsDialog({ open, onClose }) {

    const [settings, setSettings] = useState({
        approvalRequestEmail: false,
        approvalRequestApp: false,
        campaignEmail: false,
        campaignApp: false,
        surveyEmail: false,
        surveyApp: false,
    });

    const [putUserNotificationSettings] = usePutNotificationSettingsMutation();
    const { data: session } = useGetSessionQuery();
    const { data: userNotificationSettings } = useGetNotificationSettingsQuery();

    useEffect(() => {
        if (userNotificationSettings) {
            setSettings(userNotificationSettings);
        }
    }, [userNotificationSettings]);

    const notificationTypes = [
        { type: 'approvalRequest', typeLabel: "Approval Requests", access: session?.roles.some(roleAccess.approvalRequests) },
        { type: 'campaign', typeLabel: "Campaigns", access: session?.roles.some(roleAccess.campaigns) },
        { type: 'survey', typeLabel: "Surveys" },
    ];

    const handleToggle = (type) => {
        setSettings(prevSettings => ({
            ...prevSettings,
            [type]: !prevSettings[type],
        }));
    };

    const resetSettings = () => {
        if (userNotificationSettings) {
            setSettings(userNotificationSettings || {});
        }
    };

    const handleSave = () => {
        putUserNotificationSettings(settings)
            .then(res => {
                if (res.error) {
                    console.error("Error saving notification settings:", res.error);
                } else {
                    toast.success("Notification settings saved");
                    onClose();
                }
            })
            .catch((error) => {
                console.error("Error saving notification settings:", error);
                toast.error("Error saving notification settings");
            });
    };

    const handleCancel = () => {
        resetSettings();
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth='xs'
        >
            <DialogTitle>
                Notification Settings
                <IconButton
                    aria-label='close'
                    onClick={handleCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                    {notificationTypes.map(({ type, typeLabel, access = true }, index) => access && (
                        <React.Fragment key={index}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Typography variant='body1'>
                                            {typeLabel}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch checked={settings[`${type}Email`]} onChange={() => handleToggle(`${type}Email`)} />}
                                                label={<Typography variant='body2'>Email</Typography>}
                                            />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Switch checked={settings[`${type}App`]} onChange={() => handleToggle(`${type}App`)} />}
                                                label={<Typography variant='body2'>App</Typography>}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                {index !== notificationTypes.length - 1 && <Divider sx={{ pt: 1 }} />}
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
            </DialogContent>
            <DialogActionButtons
                fullWidth
                submitText="Save"
                onSubmit={handleSave}
                closeText="Cancel"
                onClose={handleCancel} />
        </Dialog>
    );
}