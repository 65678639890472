import { useMemo, useState } from "react";
import { Tooltip } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Delete, Edit, Lock, ExpandMore, ExpandLess } from "@mui/icons-material";
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import { CustomDataGridToolbar } from "../../Common/DataGrid/CustomDataGridToolbar";
import { LockOpenIcon } from "../../Icons";
import SchoolBuildingDataTable from "./SchoolBuildingDataTable";
import BulkImportDialog from "../Common/BulkImportDialog";

function SchoolManagementDataGrid({ schools, gradeLevels, isLoading, setEdit, setDelete, setLock }) {
    const [isBulkImportOpen, setIsBulkImportOpen] = useState(false);

    const { data: session = {} } = useGetSessionQuery();

    const columns = useMemo(() => {
        const gradeLevelComparator = (v1, v2) => {
            if (gradeLevels === null || gradeLevels?.length === 0) {
                return v1 - v2;
            }
            const startGL = gradeLevels.find(gl => gl.value === v1).ordinal;
            const endGL = gradeLevels.find(gl => gl.value === v2).ordinal;
            return startGL - endGL;
        }

        const columns = [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                headerAlign: 'center',
                align: 'left',
                minWidth: 175,
                getActions: ({ row }) => {
                    const isLocked = row.lockedByUserId;
                    const cnEdit = isLocked ? row.lockedByUserId !== session?.id ? 'action-locked' : 'action-blue' : 'action-blue';
                    const cnDelete = isLocked ? row.lockedByUserId !== session?.id ? 'action-locked' : 'action-red' : 'action-red';
                    const cnLock = isLocked ? row.lockedByUserId !== session?.id ? 'action-locked' : 'action-purple' : 'action-purple';
                    const isDisabled = isLocked ? row.lockedByUserId !== session?.id : false;
                    const isRemoveDisabled = row.hasActiveUsers;

                    return [
                        <Tooltip title="Edit School" key='edit-school'>
                            <GridActionsCellItem
                                icon={<Edit className={cnEdit} sx={{ fontSize: 24 }} />}
                                onClick={() => setEdit(row)}
                                label='Edit'
                                disabled={isDisabled}
                            />
                        </Tooltip>,
                        <Tooltip title={isRemoveDisabled ? "This school has active users assigned to it and cannot be removed.": "Remove School"} key='edit-school'>
                            <span>
                                <GridActionsCellItem
                                    icon={<Delete className={isRemoveDisabled ? 'action-grey' : cnDelete} sx={{ fontSize: 24 }} />}
                                    onClick={() => setDelete(row)}
                                    label='Remove'
                                    disabled={isDisabled || isRemoveDisabled}
                                />
                            </span>
                        </Tooltip>,
                        <Tooltip title={isLocked ? 'Unlock School' : 'Lock School'} key='lock-school'>
                            <GridActionsCellItem
                                icon={isLocked ? <Lock className={cnLock} sx={{ fontSize: 24 }} /> : <LockOpenIcon className='action-purple-svg' sx={{ fontSize: 24 }} />}
                                onClick={() => setLock({ id: row.id, isLocked: isLocked })}
                                label='Lock'
                                disabled={isDisabled}
                            />
                        </Tooltip>
                    ];
                }
            },
            {
                field: 'name',
                headerName: 'School Name',
                flex: 1,
                editable: false,
            },
            {
                field: 'code',
                headerName: 'School Code',
                flex: 0.33,
                editable: false,
            },
            {
                field: 'systemStatusName',
                headerName: 'Status',
                flex: 0.33,
                editable: false,
            },
            {
                field: 'startGrade',
                headerName: 'Start Grade',
                flex: 0.33,
                editable: false,
                sortComparator: gradeLevelComparator
            },
            {
                field: 'endGrade',
                headerName: 'End Grade',
                flex: 0.33,
                editable: false,
                sortComparator: gradeLevelComparator
            }
        ];

        return columns;
    }, [setDelete, setEdit, setLock, session, gradeLevels]);

    return (
        <>
            <DataGridPro
                getDetailPanelContent={({ row }) => (
                    <SchoolBuildingDataTable schoolBuildings={row.schoolBuildings} />
                )}
                getDetailPanelHeight={() => 'auto'}
                autoHeight
                slots={{
                    detailPanelExpandIcon: ExpandMore,
                    detailPanelCollapseIcon: ExpandLess,
                    toolbar: () =>
                        <CustomDataGridToolbar
                            hasWriteAccess
                            onImportClick={() => setIsBulkImportOpen(true)}
                            handleCreateNew={() => setEdit(-1)}
                        />
                }}
                rows={schools}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 25, } },
                    sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
                }}
                loading={isLoading}
                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 !== 0 ? 'grid-odd' : null}
                pageSizeOptions={[25, 50, 100]}
                disableRowSelectionOnClick
                localeText={{ noRowsLabel: 'No schools' }}
            />
            <BulkImportDialog
                type='school'
                isOpen={isBulkImportOpen}
                close={() => setIsBulkImportOpen(false)}
            />
        </>
    );
}

export default SchoolManagementDataGrid;
