import { useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { Delete, Edit, Lock, ThumbsUpDown } from '@mui/icons-material';
import { LockOpenIcon } from '../../Icons';
import { CustomDataGridToolbar } from '../../Common/DataGrid/CustomDataGridToolbar';
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import { ApprovalStatusEnum, RoleEnum } from '../../../utility/enums';
import SchoolsCell from './SchoolsCell';
import BulkImportDialog from '../Common/BulkImportDialog';

function UserManagementDataGrid({ users, isLoading, setEdit, setDelete, setLock, setEditApproval }) {
    const [isBulkImportOpen, setIsBulkImportOpen] = useState(false);

    const { data: session = {} } = useGetSessionQuery();

    const columns = useMemo(() => {
        const columns = [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                headerAlign: 'center',
                align: 'left',
                minWidth: 175,
                getActions: ({ row }) => {
                    const isLocked = row.lockedByUserId;
                    const isDisabled = isLocked ? row.lockedByUserId !== session?.id : false;
                    const hasIntegratorSessionPermissions = session?.roles?.some(x => x.role.id === RoleEnum.INTEGRATOR) && row.roles?.some(x => x.id === RoleEnum.DISTRICT_ADMIN || x.id === RoleEnum.INTEGRATOR);
                    const sessionIntegratorLocked = hasIntegratorSessionPermissions ? 'action-locked' : null;
                    const rowIsIntegrator = row?.roles?.some(x => x.id === RoleEnum.INTEGRATOR);
                    const rowIntegratorLocked = rowIsIntegrator ? 'action-locked' : null;
                    const isDeleteDisabled = row.systemStatusName === 'Removed';

                    const getColor = (color) => isLocked ? row.lockedByUserId !== session?.id ? 'action-locked' : `action-${color}` : `action-${color}`;

                    const actions = [
                        {
                            title: 'Edit User',
                            icon: <Edit sx={{ fontSize: 24 }} className={`${getColor('blue')} ${sessionIntegratorLocked} ${rowIntegratorLocked}`} />,
                            onClick: () => setEdit(row),
                            label: 'Edit',
                            disabled: rowIsIntegrator
                        },
                        {
                            title: isDeleteDisabled ? 'This user has been removed' : 'Remove User',
                            icon: <Delete sx={{ fontSize: 24 }} className={`${getColor(isDeleteDisabled ? 'grey' : 'red')} ${sessionIntegratorLocked}`} />,
                            onClick: () => setDelete(row),
                            label: 'Delete',
                            disabled: isDeleteDisabled
                        },
                        {
                            title: isLocked ? 'Unlock User Account' : 'Lock User Account',
                            icon: isLocked ? (
                                <Lock
                                    sx={{ fontSize: 24 }}
                                    className={`${getColor('purple')} ${sessionIntegratorLocked} ${rowIntegratorLocked}`}
                                />
                            ) : (
                                <LockOpenIcon
                                    sx={{ fontSize: 24 }}
                                    className={`action-purple-svg ${sessionIntegratorLocked} ${rowIntegratorLocked}`}
                                />
                            ),
                            onClick: () => setLock({ id: row.id, isLocked: isLocked }),
                            label: 'Lock',
                            disabled: rowIsIntegrator,
                        }
                    ];

                    if (row.approvalRequests.some(ar => ar.statusId === ApprovalStatusEnum.PENDING)) {
                        actions.push({
                            title: 'User Account Request',
                            icon: <ThumbsUpDown sx={{ fontSize: 24 }} className={getColor('grey')} />,
                            onClick: () => setEditApproval(row),
                            label: 'Edit Approval'
                        });
                    }

                    return actions.map(({ title, icon, onClick, label, disabled }, index) => {
                        const isActionDisabled = isDisabled || hasIntegratorSessionPermissions || disabled;
                        return isActionDisabled ?
                            <Tooltip title={title} key={index}>
                                <span>
                                <GridActionsCellItem
                                    key={index}
                                    icon={icon}
                                    onClick={onClick}
                                    label={label}
                                    disabled={isActionDisabled}
                                />
                                </span>
                            </Tooltip>
                            :
                            <Tooltip title={title} key={index}>
                                <GridActionsCellItem
                                    icon={icon}
                                    onClick={onClick}
                                    label={label}
                                />
                            </Tooltip>
                    });
                }
            },
            {
                field: 'schools',
                headerName: 'School(s)',
                flex: 1,
                editable: false,
                renderCell: (params) => <SchoolsCell {...params.row} />,
                valueGetter: (params) => params.map(school => school.name).join(', ')
            },
            {
                field: 'firstName',
                headerName: 'First Name',
                flex: .75,
                editable: false,
            },
            {
                field: 'lastName',
                headerName: 'Last Name',
                flex: .75,
                editable: false,
            },
            {
                field: 'emailAddress',
                headerName: 'Email',
                flex: 1,
                editable: false,
            },
            {
                field: 'systemStatusName',
                headerName: 'Status',
                flex: .5,
                editable: false,
            },
            {
                field: 'roles',
                headerName: 'System Role(s)',
                flex: .75,
                editable: false,
                renderCell: ({ row }) => row.roles?.map(x => x.name).join(', '),
                valueGetter: (params) => params.map(role => role.name).join(', ')
            },
            {
                field: 'studentIds',
                headerName: 'Student IDs',
                flex: .75,
                editable: false,
                renderCell: ({ row }) => row.studentIds?.map(x => x.value).join(', '),
                valueGetter: (params) => params.map(studentId => studentId.value).join(', ')
            }
        ];

        return columns;
    }, [setDelete, setEdit, setLock, setEditApproval, session]);

    return (
        <>
            <DataGridPro
                autoHeight
                slots={{
                    toolbar: () =>
                        <CustomDataGridToolbar
                            hasWriteAccess
                            onImportClick={() => setIsBulkImportOpen(true)}
                            handleCreateNew={() => setEdit(-1)}
                        />
                }}
                rows={users}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 25, } },
                    sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
                }}
                loading={isLoading}
                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 !== 0 ? 'grid-odd' : null}
                pageSizeOptions={[25, 50, 100]}
                disableRowSelectionOnClick
                localeText={{ noRowsLabel: 'No users' }}
            />
            <BulkImportDialog
                type='user'
                isOpen={isBulkImportOpen}
                close={() => setIsBulkImportOpen(false)}
            />
        </>
    );
}

export default UserManagementDataGrid;