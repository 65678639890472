import { api } from '../api';

export const patchNoteApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPatchNotes: builder.query({
            query: () => '/patchnote',
            providesTags: (result) =>
                result
                    ? result.map(({ id }) => ({ type: 'PatchNote', id }))
                    : ['PatchNote'],
        })
    }),
});


export const {
    useGetPatchNotesQuery
} = patchNoteApi;