import { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, IconButton, TextField, Tooltip, Typography, Slider, Autocomplete, Chip, Button, Skeleton, Stack } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import { toast } from "react-toastify";
import styled from 'styled-components';
import { useAddSchoolMutation, useUpdateSchoolMutation } from './schoolManagementSlice';
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import { useGetSystemStatusesQuery } from '../../../app/slices/systemStatusSlice';
import DialogActionButtons from '../../Common/Buttons/DialogActionButtons';
import SchoolBuildingFormModal from './SchoolBuildingFormModal';
import ConfirmationModal from '../../Common/ConfirmationModal';

const GrayBorderBox = styled(Box)({
    padding: '15px',
    border: '1px solid #9F9F9F',
    borderRadius: '5px',
    backgroundColor: '#FAFAFA',
});

const initialBuildingFormState = { id: null, name: '' };

function SchoolFormModal({ isOpen, school, close, gradeLevels }) {
    const [schoolName, setSchoolName] = useState('');
    const [code, setCode] = useState('');
    const [status, setStatus] = useState(null);
    const [gradeSliderStart, setGradeSliderStart] = useState(0)
    const [gradeSliderEnd, setGradeSliderEnd] = useState(gradeLevels.length - 1)
    const [hasActiveUsers, setHasActiveUsers] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [buildings, setBuildings] = useState([])
    const [buildingForm, setBuildingForm] = useState(null);
    const [isBuildingFormVisible, setIsBuildingFormVisible] = useState(false);
    const [isEditingBuilding, setIsEditingBuilding] = useState(false);
    const [buildingToDelete, setBuildingToDelete] = useState(null);
    const [showBuildingDeleteModal, setShowBuildingDeleteModal] = useState(false);
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
    const [addSchool, { isLoading: isAdding }] = useAddSchoolMutation();
    const [updateSchool, { isLoading: isUpdating }] = useUpdateSchoolMutation();
    const { data: session = {} } = useGetSessionQuery();
    const { data: statuses = [] } = useGetSystemStatusesQuery();

    useEffect(() => {
        if (!school || !school.id) return;

        setGradeSliderStart(gradeLevels.findIndex(x => x.id === school?.startGradeId) ?? 0);
        setGradeSliderEnd(gradeLevels.findIndex(x => x.id === school?.endGradeId) ?? gradeLevels.length - 1);
        setSchoolName(school.name);
        setCode(school.code);
        setStatus(school.systemStatusName);
        setBuildings(school.schoolBuildings);
        setHasActiveUsers(school.hasActiveUsers);
    }, [school, gradeLevels]);

    function resetBuildings() {
        setBuildings(school?.schoolBuildings ?? []);
    }

    function clear() {
        setSchoolName('');
        setCode('');
        setStatus(null);
        setGradeSliderStart(0);
        setGradeSliderEnd(gradeLevels.length - 1);
        setBuildings([]);
        setIsFormDirty(false);
        setFormErrors({});
    }

    const clearBuildingForm = () => {
        setBuildingForm(initialBuildingFormState);
        setIsBuildingFormVisible(false);
        setIsEditingBuilding(false);
    };

    const handleBuildingClick = (building) => {
        setBuildingForm({ ...building });
        setIsBuildingFormVisible(true);
        setIsEditingBuilding(true);
    };

    const handleBuildingUpdate = () => {
        const { id, name } = buildingForm;
        if (!name.trim()) {
            setFormErrors((prev) => ({ ...prev, buildingName: 'Name is required' }));
            return;
        }
        if (buildings.some(x => x.name.toLowerCase() === name.toLowerCase() && !(x.id === id))) {
            setFormErrors((prev) => ({ ...prev, buildingName: 'Building name already entered' }));
            return;
        }
        if (id !== null) {
            // Update existing building
            setBuildings((prev) =>
                prev.map((building) => (building.id === id ? { ...building, name } : building))
            );
            setIsFormDirty(true)
        } else {
            // Add new building and give it a temporary id
            const tempId = buildings.length > 0 ? Math.max(...buildings.map((b) => b.id)) + 1 : 1;
            setBuildings((prev) => [...prev, { id: tempId, name }]);
            setIsFormDirty(true)
        }
        clearBuildingForm();
    };

    const handleBuildingFormChange = (field, value) => {
        setBuildingForm((prev) => ({ ...prev, [field]: value }));
    };

    const handleBuildingRemove = (building) => {
        setBuildings((prev) => prev.filter((x) => x.id !== building.id));
        clearBuildingForm();
        setIsFormDirty(true);
    };




    const handleCloseBuildingForm = () => {
        setIsBuildingFormVisible(false);
        setFormErrors((prev) => ({ ...prev, buildingName: null }));

    };


    function isFormValid() {
        const errors = {};

        errors['school-name'] = !schoolName ? 'Field is required' : null;
        errors['code'] = !code?.trim() ? 'Field is required' : null;
        if (school?.id)
            errors['status'] = !status?.trim() ? 'Field is required' : null;
        if (!errors['code'])
            errors['code'] = code.length > 6 ? 'Must be less than 6 characters' : null;

        if (!errors['code'])
            errors['code'] = code.length < 4 ? 'Must be more than 4 characters' : null;

        setFormErrors(errors);

        for (let prop in errors)
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    }

    function validate(e) {
        setFormErrors(prev => ({
            ...prev,
            [e.target.id]: !e.target.value.trim() ? 'Field is required' : null
        }));
    }

    function handleClose() {
        if (!isFormDirty) {
            handleConfirmClose();
        }
        else {
            setShowUnsavedChangesModal(true);
        }
    }

    function handleConfirmClose() {
        setShowUnsavedChangesModal(false);
        clear();
        close();
    }
    const handleGradeLevelChange = (e, newValue) => {
        setGradeSliderStart(newValue[0]);
        setGradeSliderEnd(newValue[1]);
        setIsFormDirty(true);
    }

    function submit() {
        if (!isFormValid())
            return;

        const dto = {
            schoolDistrictId: session.schoolDistrictId,
            name: schoolName,
            code,
            startGradeId: gradeLevels[gradeSliderStart].id,
            endGradeId: gradeLevels[gradeSliderEnd].id,
            systemStatusId: statuses.find(x => x.value === status)?.id,
        }

        if (school?.id) {
            dto.id = school.id;
            dto.schoolDistrictId = school.schoolDistrictId;
            dto.buildings = buildings;
            updateSchool(dto)
                .unwrap()
                .then((response) => {
                    if (response?.error)
                        throw new Error("An error occurred when updating school");

                    if (response?.codeError || response?.nameError) {
                        let errors = {
                            ...formErrors,
                            'code': response.codeError,
                            'school-name': response.nameError,
                        };
                        setFormErrors(errors);
                        return;
                    }
                    else if (response?.buildingError) {
                        let errors = {
                            ...formErrors,
                            'remove-building-name': response.buildingError,
                        };
                        setFormErrors(errors);
                        resetBuildings()
                        return;
                    }
                    toast.success('Successfully updated school');
                    clear();
                    close();
                })
                .catch(error => {
                    toast.error(error?.data?.detail ?? "An error occurred updating");
                    return;
                });
        } else {
            dto.buildingNames = buildings.map(x => x.name);
            addSchool(dto)
                .unwrap()
                .then((response) => {
                    if (response?.error)
                        throw new Error("An error occurred when adding school");

                    if (response?.codeError || response?.nameError) {
                        let errors = {
                            ...formErrors,
                            'code': response.codeError,
                            'school-name': response.nameError
                        };
                        setFormErrors(errors);
                        return;
                    }
                    toast.success('Successfully added school');
                    clear();
                    close();
                })
                .catch(error => {
                    toast.error(error);
                    return;
                });
        }
    }

    return (
        <>
            <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>
                    <Box>
                        {school === -1 ? "Add School" : school?.id ? "Edit School" : <Skeleton variant='text' width={100} sx={{ visibility: 'hidden' }} />}
                    </Box>
                    <IconButton
                        aria-label="close"
                        onClick={() => { close(); clear(); }}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ paddingTop: '.3em !important', paddingBottom: 1 }}>
                    <Stack spacing={3}>
                        <TextField
                            fullWidth
                            id="school-name"
                            size='small'
                            label="School Name"
                            variant="outlined"
                            type="text"
                            required
                            value={schoolName}
                            onBlur={validate}
                            error={!!formErrors['school-name']}
                            helperText={formErrors['school-name']}
                            onChange={e => { setIsFormDirty(true); setSchoolName(e.target.value) }}
                        />
                        <Box sx={{ display: 'flex', gap: '20px' }}>
                            <TextField
                                fullWidth
                                id="code"
                                size='small'
                                label="Code"
                                variant="outlined"
                                type="text"
                                required
                                value={code}
                                onBlur={validate}
                                error={!!formErrors['code']}
                                helperText={formErrors['code']}
                                onChange={e => { setIsFormDirty(true); setCode(e.target.value) }}
                            />
                            <Tooltip title={hasActiveUsers ? "School has active users assigned and the status cannot be changed." : ""} key='status'>
                                <span style={{ display: 'inline-block', width: '395px' }}>
                                    <Autocomplete
                                        fullWidth
                                        id="status"
                                        size='small'
                                        options={statuses.map(x => x.value)}
                                        value={status}
                                        onChange={(_, v) => setStatus(v)}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Status"
                                                required
                                                onBlur={validate}
                                                error={!!formErrors['status']}
                                                helperText={formErrors['status']}
                                            />}
                                        disabled={hasActiveUsers}
                                    />
                                </span>
                            </Tooltip>
                        </Box>
                        <Box sx={{ px: 1 }}>
                            <Typography variant='caption'>
                                Grade Levels: &nbsp;&nbsp; {gradeLevels[gradeSliderStart ?? 0]?.value} - {gradeLevels[gradeSliderEnd ?? gradeLevels.length - 1]?.value}
                            </Typography>
                            <Slider
                                value={[gradeSliderStart, gradeSliderEnd]}
                                valueLabelDisplay="off"
                                marks={gradeLevels.map((x, i) => ({ value: i, label: x.value }))}
                                min={0}
                                step={1}
                                max={gradeLevels?.length - 1 || 0}
                                onChange={handleGradeLevelChange}
                                sx={{
                                    '& .MuiInputBase-root': {
                                        backgroundColor: 'white'
                                    },
                                    '.MuiSlider-markLabel': {
                                        fontSize: '0.75rem',
                                    }
                                }}
                                color='secondary'
                            />
                        </Box>

                        <GrayBorderBox>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="body1">Buildings:</Typography>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    startIcon={<Add />}
                                    onClick={() => {
                                        clearBuildingForm();
                                        setIsBuildingFormVisible(true);
                                    }}
                                >
                                    Create New
                                </Button>
                            </Box>
                            {buildings.length > 0 ? (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                                    {buildings.map((building) => (
                                        <>
                                            <Chip
                                                label={building.name}
                                                onDelete={() => {
                                                    setBuildingToDelete(building);
                                                    setShowBuildingDeleteModal(true);
                                                }}
                                                onClick={() => handleBuildingClick(building)}
                                            />
                                        </>
                                    ))}
                                </Box>
                            ) : (
                                <Chip label="No buildings added" />
                            )}
                        </GrayBorderBox>
                    </Stack>
                </DialogContent>
                <DialogActionButtons
                    fullWidth
                    closeText='Cancel'
                    onClose={handleClose}
                    submitText='Save'
                    onSubmit={submit}
                    isLoading={isUpdating || isAdding}
                />
            </Dialog >
            <SchoolBuildingFormModal
                isOpen={isBuildingFormVisible}
                onClose={handleCloseBuildingForm}
                buildingForm={buildingForm}
                handleBuildingFormChange={handleBuildingFormChange}
                handleBuildingUpdate={handleBuildingUpdate}
                isEditingBuilding={isEditingBuilding}
                formErrors={formErrors}
            />
            <ConfirmationModal
                isOpen={showBuildingDeleteModal}
                close={() => setShowBuildingDeleteModal(false)}
                onConfirm={() => {
                    handleBuildingRemove(buildingToDelete);
                    setShowBuildingDeleteModal(false);
                }}
                title={`Remove ${buildingToDelete?.name}?`}
                message="Please confirm you'd like to remove the selected building."
            />
            <ConfirmationModal
                isOpen={showUnsavedChangesModal}
                close={() => setShowUnsavedChangesModal(false)}
                onConfirm={handleConfirmClose}
                title="Unsaved Changes"
                message="You have unsaved changes. Are you sure you want to discard them?"
            />
        </>

    )
}

export default SchoolFormModal;