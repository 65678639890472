import { useState } from 'react';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Box, Button, IconButton, Typography, MenuItem, Stack, Tooltip, Menu, Card, FormHelperText } from '@mui/material';
import { Add, Visibility } from '@mui/icons-material';
import { useGetSurveyQuestionTypesQuery } from '../../../app/slices/surveyQuestionTypesSlice';
import { SurveyQuestionScoringTypeEnum, SurveyQuestionTypeEnum } from '../../../utility/enums';
import QuestionBase from './StepThreeComponents/QuestionBase';
import WizardButtonGroup from '../../Common/Buttons/WizardButtonGroup';
import SurveyTitleHeader from './Common/SurveyTitleHeader';
import SurveyCompletionModal from "../../Common/Surveys/SurveyCompletionModal"
import { int32RandomUUID } from '../../../utility/uuid';

const defaultQuestionText = "Question text";

export default function StepThree({ title, questions, setQuestions, nextStep, prevStep }) {
    const [isSurveyPreviewOpen, setIsSurveyPreviewOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [formErrors, setFormErrors] = useState({});
    const [editingStates, setEditingStates] = useState({});

    const { data: questionTypes = [] } = useGetSurveyQuestionTypesQuery();

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (surveyQuestionTypeId) => {
        const newQuestion = {
            id: int32RandomUUID(), // Must be given a unique id for the sortable context
            surveyQuestionTypeId,
            scoringTypeId: surveyQuestionTypeId === SurveyQuestionTypeEnum.SHORT_ANSWER
                ? SurveyQuestionScoringTypeEnum.NOT_SCORED
                : SurveyQuestionScoringTypeEnum.IN_ORDER,
            ordinal: questions.length + 1,
            prompt: defaultQuestionText,
            isRequired: false,
            surveyQuestionOptions: surveyQuestionTypeId === SurveyQuestionTypeEnum.YES_OR_NO // yes or no is the only one with pre-determined options
                ? [{ title: 'Yes', id: 1, ordinal: 1 }, { title: 'No', id: 2, ordinal: 2 }]
                : []
        };
        setQuestions([...questions, newQuestion]);
        setFormErrors({ '0': { noQuestions: null } });
        handleCloseMenu();
    };

    const onUpdateQuestion = (ordinal, question) => {
        const copied = [...questions];
        const updated = { ...questions.find(q => q.ordinal === ordinal), ...question };

        copied[updated.ordinal - 1] = updated;

        setQuestions(copied);
    };

    const onCopyQuestion = (ordinal) => {
        const copied = [...questions];
        let duplicated = { ...copied[ordinal - 1] };

        duplicated.ordinal = questions.length + 1;
        duplicated.id = int32RandomUUID();

        setFormErrors(prevErrors => ({
            ...prevErrors,
            [duplicated.ordinal]: {}
        }));

        setQuestions([...copied, duplicated]);
    };

    const onDeleteQuestion = (ordinal) => {
        const filtered = questions.filter(q => q.ordinal !== ordinal);

        const updatedQuestions = filtered.map((q, i) => ({
            ...q,
            ordinal: i + 1
        }));

        setQuestions(updatedQuestions);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) return;

        if (active.id !== over.id) {
            const oldIndex = questions.findIndex((q) => q.id === active.id);
            const newIndex = questions.findIndex((q) => q.id === over.id);

            const movedQuestions = arrayMove(questions, oldIndex, newIndex).map((q, index) => ({
                ...q,
                ordinal: index + 1
            }));

            setQuestions(movedQuestions);
        }
    };

    const setIsEditing = (ordinal, isEditing) => {
        setEditingStates(prev => ({
            ...prev,
            [ordinal]: isEditing
        }));
    };

    const handleSetFormErrors = (ordinal, errors) => {
        setFormErrors(prevErrors => ({
            ...prevErrors,
            [ordinal]: errors
        }));
    };

    const validateQuestions = () => {
        let isValid = true;
        const accumulatedErrors = {};

        if (questions.length < 1) {
            setFormErrors({ '0': { noQuestions: 'At least one question is required' } });
            return false;
        }

        questions.forEach(q => {
            const errors = {};
            errors['promptField'] = !q.prompt || q.prompt.trim() === defaultQuestionText ? 'Question text is required' : null;
            errors['optionsArray'] = q.surveyQuestionTypeId !== SurveyQuestionTypeEnum.SHORT_ANSWER && (!q.surveyQuestionOptions || q.surveyQuestionOptions.length < 1) ? 'At least one option is required' : null;

            if (errors['promptField'] || errors['optionsArray']) {
                isValid = false;
                accumulatedErrors[q.ordinal] = errors;
                setIsEditing(q.ordinal, true);
            }
        });

        setFormErrors(accumulatedErrors);
        return isValid;
    };

    const toNextStep = () => {
        if (validateQuestions()) {
            nextStep(questions);
        }
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    padding: '0% 3% 0% 3%',
                    maxWidth: '1100px',
                }}
            >
                <SurveyTitleHeader title={title} />
                <Card
                    sx={{
                        flexBasis: '75%',
                        padding: '25px',
                        mb: '25px',
                        backgroundColor: (theme) => theme.palette.grey[100]
                    }}
                >
                    <Stack spacing={3} sx={{ width: '100%' }}>
                        {questions.length > 0 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 5 }}>
                                <Typography>
                                    <span style={{ fontWeight: 'bold' }}>Question Builder</span>
                                </Typography>
                                <IconButton onClick={() => setIsSurveyPreviewOpen(true)}
                                    aria-label='View Survey Preview'

                                >
                                    <Tooltip title={"View Survey Preview"}>
                                        <Visibility color='secondary' />
                                    </Tooltip>

                                </IconButton>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '100%' }}>
                                    <span style={{ fontWeight: 'bold' }}>Add Survey Questions. </span>
                                    To build your survey, click &quot;Add Question&quot; and select the question type. Questions added will be included in all surveys created from this template.
                                </Typography>
                            </Box>
                        )}
                        <DndContext
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={questions}
                                strategy={verticalListSortingStrategy}
                            >
                                {questions.map(q => (
                                    <QuestionBase
                                        key={q.id}
                                        id={q.id}
                                        question={q}
                                        defaultPrompt={defaultQuestionText}
                                        isEditing={editingStates[q.ordinal]}
                                        setIsEditing={(reset) => setIsEditing(q.ordinal, reset)}
                                        onCopy={onCopyQuestion}
                                        onDelete={onDeleteQuestion}
                                        onChange={onUpdateQuestion}
                                        formErrors={formErrors?.[q.ordinal]}
                                        setFormErrors={(errors) => handleSetFormErrors(q.ordinal, errors)}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {formErrors['0']?.noQuestions && (
                                <FormHelperText error={true}>
                                    {formErrors['0']?.noQuestions}
                                </FormHelperText>
                            )}
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant='text'
                                color='secondary'
                                startIcon={<Add color='secondary' />}
                                onClick={handleOpenMenu}
                            >
                                {questions.length > 0 ? 'Add Question' : 'Add Question*'}
                            </Button>
                            <Menu
                                id='question-type-menu'
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                            >
                                {questionTypes.map((qt) => (
                                    <MenuItem key={qt.id} onClick={() => handleMenuItemClick(qt.id)}>
                                        <Typography variant='body2'>
                                            {qt.value}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Stack>
                </Card>
                <WizardButtonGroup
                    backClick={prevStep}
                    forwardClick={toNextStep}
                />
            </Box >
            <SurveyCompletionModal isPreview open={isSurveyPreviewOpen} onClose={() => setIsSurveyPreviewOpen(false)} surveyName={title} previewQuestions={questions} />
        </>
    );
}