import { api } from '../../app/api';
const TAG = 'SurveyTemplates';

export const surveyTemplatesApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getSurveyTemplateQuestions: builder.query({
            query: (surveyTemplateId) => ({
                url: 'surveyTemplate/get-questions',
                params: { surveyTemplateId }
            }),
            providesTags: () => [{ type: TAG }, { type: 'SurveyManagement' }, { type: 'DistrictSpecific' }]
        })
    })
})

export const {
    useGetSurveyTemplateQuestionsQuery,
} = surveyTemplatesApi;