import { Tooltip } from "@mui/material";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Delete, Edit, Lock, ExpandMore, ExpandLess } from "@mui/icons-material";
import { useGetSessionQuery } from '../../../app/slices/sessionSlice';
import { CustomDataGridToolbar } from "../../Common/DataGrid/CustomDataGridToolbar";
import { LockOpenIcon } from "../../Icons";
import BenefitDataTable from "./BenefitDataTable";
import { useMemo } from "react";

function PillarManagementDataGrid({ pillars, isLoading, setEdit, setDelete, setLock }) {
    const { data: session = {} } = useGetSessionQuery();

    const columns = useMemo(() => {
        return [
            {
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                headerAlign: 'center',
                align: 'left',
                minWidth: 175,
                getActions: ({ row }) => {
                    const isLocked = row.lockedByUserId !== null;
                    const isRemoved = row.systemStatusName === "Removed"
                    const isDisabledByLock = isLocked && row.lockedByUserId !== session.id;
                    const isLockedOrDisabled = isDisabledByLock || isRemoved || row.hasCampaigns;
                    let cnEdit = 'action-blue';
                    let cnDelete = 'action-red';
                    let cnLock = 'action-purple';
                    let editTooltip = 'Edit Pillar';
                    let deleteTooltip = 'Remove Pillar'
                    let lockTooltip = 'Lock Pillar';
                    if (isDisabledByLock) {
                        cnEdit = 'action-locked';
                        cnDelete = 'action-locked';
                        cnLock = 'action-locked';
                        editTooltip = 'Locked';
                        deleteTooltip = 'Locked';
                        lockTooltip = 'Locked';
                    }
                    else if (isRemoved) {
                        cnEdit = 'action-removed';
                        cnDelete = 'action-removed';
                        cnLock = 'action-purple';
                        editTooltip = 'Removed';
                        deleteTooltip = 'Removed';
                    }
                    else if (row.hasCampaigns) {
                        cnEdit = 'action-disabled';
                        cnDelete = 'action-disabled';
                        cnLock = 'action-purple';
                        editTooltip = 'This Pillar is associated with a Campaign and cannot be edited.'
                        deleteTooltip = 'This Pillar is associated with a Campaign and cannot be removed.'
                    }

                    return [
                        <Tooltip title={editTooltip} key='edit-pillar'>
                            <span>
                                <GridActionsCellItem
                                    icon={<Edit className={cnEdit} sx={{ fontSize: 24 }} />}
                                    onClick={() => setEdit(row)}
                                    label='Edit'
                                    disabled={isLockedOrDisabled}
                                />
                            </span>
                        </Tooltip>,
                        <Tooltip title={deleteTooltip} key='delete-pillar'>
                            <span>
                                <GridActionsCellItem
                                    icon={<Delete className={cnDelete} sx={{ fontSize: 24 }} />}
                                    onClick={() => setDelete(row)}
                                    label='Remove'
                                    disabled={isLockedOrDisabled}
                                />
                            </span>
                        </Tooltip>,
                        <Tooltip title={lockTooltip} key='lock-pillar'>
                            <span>
                                <GridActionsCellItem
                                    icon={isLocked ? <Lock className={cnLock} sx={{ fontSize: 24 }} /> : <LockOpenIcon className='action-purple-svg' sx={{ fontSize: 24 }} />}
                                    onClick={() => setLock({ id: row.id, isLocked: isLocked })}
                                    label='Lock'
                                    disabled={isDisabledByLock}
                                />
                            </span>
                        </Tooltip>
                    ];
                }
            },
            {
                field: 'name',
                headerName: 'Pillar Name',
                flex: .66,
                editable: false,
            },
            {
                field: 'description',
                headerName: 'Pillar Description',
                flex: 1,
                editable: false,
            },
            {
                field: 'systemStatusName',
                headerName: 'Status',
                flex: 0.25,
                editable: false,
            }
        ];
    }, [setDelete, setEdit, setLock, session]);

    return (
        <DataGridPro
            getDetailPanelContent={({ row }) => {
                if (!row.benefits || row.benefits.length === 0) return null;
                else return <BenefitDataTable benefits={row.benefits} />
            }}
            getDetailPanelHeight={() => 'auto'}
            autoHeight
            slots={{
                detailPanelExpandIcon: ExpandMore,
                detailPanelCollapseIcon: ExpandLess,
                toolbar: () =>
                    <CustomDataGridToolbar
                        hasWriteAccess
                        handleCreateNew={() => setEdit(-1)}
                    />
            }}
            rows={pillars}
            columns={columns}
            initialState={{
                pagination: { paginationModel: { pageSize: 25, } },
                sorting: { sortModel: [{ field: 'name', sort: 'asc' }] },
            }}
            loading={isLoading}
            getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 !== 0 ? 'grid-odd' : null}
            pageSizeOptions={[25, 50, 100]}
            disableRowSelectionOnClick
            localeText={{ noRowsLabel: 'No pillars' }}
        />
    );
}

export default PillarManagementDataGrid;
