import { useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmationModal from '../Common/ConfirmationModal';
import ContentWrapper from '../Common/ContentWrapper';
import { RoleEnum } from '../../utility/enums';
import { useGetSessionQuery } from '../../app/slices/sessionSlice';
import { useGetSurveyTemplateStatusQuery } from '../../app/slices/surveyTemplateStatusSlice';
import { useGetAllSurveyTemplatesQuery, useDeleteSurveyTemplateMutation, useCancelSurveyTemplateMutation } from './surveyTemplateManagementSlice';
import { SurveyManagementDataGrid } from './SurveyManagementDataGrid';
import { Button, Typography } from "@mui/material";
import { Visibility, VisibilityOff} from '@mui/icons-material';

const writeRolePermissions = [RoleEnum.DISTRICT_ADMIN, RoleEnum.INTEGRATOR];

export default function SurveyManagementContainer() {
    const [deletingSurvey, setDeletingSurvey] = useState(null);
    const [cancellingSurvey, setCancellingSurvey] = useState(null);

    const { data: surveys = [], isLoading } = useGetAllSurveyTemplatesQuery();
    const [deleteSurveyTemplate] = useDeleteSurveyTemplateMutation();
    const [cancelSurveyTemplate] = useCancelSurveyTemplateMutation();
    const { data: surveyTemplateStatuses = [] } = useGetSurveyTemplateStatusQuery();

    const { data: session, isLoading: isSessionLoading } = useGetSessionQuery();
    const [searchParams] = useSearchParams();
    const inboundSurveyTemplateId = useMemo(() => searchParams.get('surveyTemplateId'), [searchParams]);

    const hasWriteAccess = session?.roles.some(r => writeRolePermissions.includes(r.role.id));

    const [visible, setVisible] = useState(false);
    const toggleVisibility = () => setVisible((prev) => !prev);
    
    function onDeleteSurvey(id) {
        deleteSurveyTemplate(id)
            .then((response) => {
                const isDeleting = response?.data?.isDeleting;

                if (isDeleting === null)
                    toast.error(`Failed to remove survey template`);

                setDeletingSurvey(null);
                toast.success('Successfully removed survey template');
            })
            .catch((error) => {
                toast.error(error);
                setDeletingSurvey(null);
            });
    };

    function onCancelSurvey(id) {
        cancelSurveyTemplate(id)
            .then((response) => {
                const isCancelling = response?.data?.isCancelling;

                if (isCancelling === null)
                    toast.error(`Failed to cancel survey template`);

                setCancellingSurvey(null);
                toast.success('Successfully cancelled survey template');
            })
            .catch((error) => {
                toast.error(error);
                setCancellingSurvey(null);
            });

    };

    return (
        <>
            <ContentWrapper title="Survey Template Management">
                <Button
                    size='large'
                    disableElevation
                    variant={'outlined'}
                    onClick={toggleVisibility} 
                    endIcon={visible ? <Visibility sx={{ color: 'grey' }}/> : <VisibilityOff sx={{ color: 'grey' }}/>}
                    sx={{
                        maxWidth: '125px',
                        borderColor: theme => theme.palette.grey[500],
                        justifyContent: 'space-between',
                        height: '100%',
                    }}
                >
                    <Typography
                        variant='body2'
                        sx={{
                            whiteSpace: 'nowrap', color: (theme) => theme.palette.grey[600],
                            ml: '-8px',
                        }}
                    >
                        {'Completed'}
                    </Typography>
                </Button>
                <SurveyManagementDataGrid
                    statusOptions={surveyTemplateStatuses}
                    surveys={surveys}
                    inboundSurveyTemplateId={inboundSurveyTemplateId}
                    isLoading={isLoading}
                    isSessionLoading={isSessionLoading}
                    setDelete={setDeletingSurvey}
                    setCancel={setCancellingSurvey}
                    hasWriteAccess={hasWriteAccess}
                    completedVisible={visible}
                />
            </ContentWrapper>
            <ConfirmationModal
                isOpen={!!deletingSurvey}
                close={() => setDeletingSurvey(null)}
                onConfirm={() => onDeleteSurvey(deletingSurvey.id)}
                title={`Remove ${deletingSurvey && deletingSurvey.name}`}
                message="Please confirm you'd like to remove the selected survey template."
                saveBtnText="Remove"
            />
            <ConfirmationModal
                isOpen={!!cancellingSurvey}
                close={() => setCancellingSurvey(null)}
                onConfirm={() => onCancelSurvey(cancellingSurvey.id)}
                title={`Cancel ${cancellingSurvey && cancellingSurvey.name}`}
                message="Please confirm you'd like to cancel the selected survey template."
                saveBtnText="Cancel Survey Template"
                cancelBtnText="Do Not Cancel Survey Template"
            />
        </>
    )
}