import { Typography, Dialog, DialogContent, Divider, List, ListItem, Skeleton, DialogTitle, IconButton, Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import PillarXpLogo from '../../images/PillarXP-Logo-Alt.svg';
import { useGetPatchNotesQuery } from '../../app/slices/patchNoteSlice';
import styled from 'styled-components';

const DialogImage = styled.img`
  margin: 1em; 
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export default function PatchNotesModal({ open, onClose }) {
    const { data, isLoading, isError } = useGetPatchNotesQuery({
        skip: !open // Only fetch when the modal is open
    });

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
            <DialogTitle>
                <DialogImage src={PillarXpLogo} alt='logo' />
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
                <Divider />
            </DialogTitle>
            <DialogContent sx={{ pb: 5 }}>
                {isError && !isLoading ? <Typography color='red' variant='body1' align='center'>Error loading patch notes. Please contact system administrators.</Typography> : null}
                {isLoading ? <Skeleton /> : null}
                {!isError && !isLoading ?
                    data.map(x => (
                        <Box key={`patch-note-${x.id}`}>
                            <Typography variant='h6'>
                                {x.version} - {x.title}
                            </Typography>
                            <Typography variant='body2'>
                                {x.overview}
                            </Typography>
                            <Box sx={{paddingLeft: '35px'}}>
                                <List sx={{ listStyleType: 'disc' }}>
                                    {x.lineItems.map(y => <ListItem sx={{ display: 'list-item' }}>{y.text}</ListItem>)}
                                </List>
                            </Box>
                        </Box>
                    )) : null
                }
                {!isError && !isLoading && !data?.length ? <Box>No Patch Notes</Box> : null}
            </DialogContent>
        </Dialog>
    )
}