import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LinearProgress, Link, Tooltip } from '@mui/material';
import { DataGridPro, GridActionsCellItem, } from '@mui/x-data-grid-pro';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CancelRounded, CopyAllRounded, Delete, Edit } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { CustomDataGridToolbar } from '../Common/DataGrid/CustomDataGridToolbar';
import SurveyTemplateResults from './SurveyTemplatesResults';
import SurveyTemplateDetailsModal from './SurveyTemplateDetailsModal';
import { useGetSurveyTemplateExpandedDetailsMutation } from './surveyTemplateManagementSlice';
import { SystemStatusEnum } from '../../utility/enums';

export function SurveyManagementDataGrid({ surveys, isLoading, isSessionLoading, setDelete, setCancel, hasWriteAccess, statusOptions, inboundSurveyTemplateId, completedVisible }) {
    const [selectedSurveyTemplate, setSelectedSurveyTemplate] = useState(null);
    const [isSurveyTemplateDetailsOpen, setIsSurveyTemplateDetailsOpen] = useState(false);
    const [getSurveyTemplateExpandedDetailsQuery, { isLoading: isLoadingDetails }] = useGetSurveyTemplateExpandedDetailsMutation();
    const filteredSurveys = surveys.filter(survey => survey.status !== 'Completed');

    const navigate = useNavigate();

    const nameClick = useCallback(id => {
        setIsSurveyTemplateDetailsOpen(true);
        setSelectedSurveyTemplate(null);

        getSurveyTemplateExpandedDetailsQuery({ id })
            .unwrap()
            .then(res => {
                setSelectedSurveyTemplate(res);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [getSurveyTemplateExpandedDetailsQuery, setSelectedSurveyTemplate]);

    // if the user gets here from notifications, display the details modal
    useEffect(() => {
        if (!inboundSurveyTemplateId)
            return;

        getSurveyTemplateExpandedDetailsQuery({ id: inboundSurveyTemplateId })
            .unwrap()
            .then((response) => {
                if (response.systemStatusId === SystemStatusEnum.REMOVED) {
                    toast.error('The selected survey template has been removed and is no longer available.');
                    return;
                }

                if (new Date(response.endDate) <= new Date()) {
                    toast.error('The selected survey template has expired.');
                    return;
                }

                setSelectedSurveyTemplate(response);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [inboundSurveyTemplateId, getSurveyTemplateExpandedDetailsQuery]);

    const columns = useMemo(() => {
        const columns = []

        if (hasWriteAccess) {
            columns.push({
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                headerAlign: 'center',
                align: 'center',
                minWidth: 150,
                getActions: ({ row }) => {
                    const isDisabled = (row.status === 'Active' || row.status === 'Completed' || row.status === 'Cancelled');
                    return [
                        <Tooltip title={isDisabled ? row.status + ' survey templates cannot be edited.' : 'Edit Survey Template'} key='edit-survey'>
                            <span>
                                <GridActionsCellItem
                                    icon={<Edit className={isDisabled ? 'action-grey' : 'action-blue'} sx={{ fontSize: 24 }} />}
                                    label={'Edit'}
                                    onClick={() => navigate(`/survey-template-management/wizard/${row.id}`)}
                                    disabled={isDisabled}
                                />
                            </span>
                        </Tooltip>,
                        <Tooltip title='Remove Survey Template' key='delete-survey'>
                            <GridActionsCellItem
                                icon={<Delete className='action-red' sx={{ fontSize: 24 }} />}
                                label={'Delete'}
                                onClick={() => setDelete(row)}
                            />
                        </Tooltip>,
                        row.status === 'Cancelled' ?
                            <GridActionsCellItem
                                icon={<CancelRounded className='action-grey' sx={{ fontSize: 24 }} />}
                                label={'Cancelled'}
                                disabled
                            />
                            :
                            <Tooltip title='Cancel Survey Template' key='cancel-survey'>
                                <GridActionsCellItem
                                    icon={<CancelRounded className='action-blue' sx={{ fontSize: 24 }} />}
                                    label={'Cancel'}
                                    onClick={() => setCancel(row)}
                                />
                            </Tooltip>
                        ,
                        <Tooltip title='Copy Survey Template' key='copy-survey'>
                            <GridActionsCellItem
                                icon={<CopyAllRounded className='action-blue' sx={{ fontSize: 24 }} />}
                                label={'Copy'}
                                onClick={() => navigate(`/survey-template-management/wizard/new/${row.id}`)}
                            />
                        </Tooltip>
                    ];
                }
            });
        }

        columns.push(
            {
                field: 'name',
                headerName: 'Name',
                flex: .75,
                editable: false,
                renderCell: ({ row }) => (
                    <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() => nameClick(row.id)}
                    >
                        {row.name}
                    </Link>
                ),
            },
            {
                type: 'date',
                field: 'createdDate',
                headerName: 'Created Date',
                flex: .4,
                editable: false,
                valueGetter: value => new Date(value),
            },
            {
                type: 'date',
                field: 'startDate',
                headerName: 'Start',
                flex: .4,
                editable: false,
                valueGetter: value => new Date(value),
            },
            {
                type: 'date',
                field: 'nextDate',
                headerName: 'Next',
                flex: .4,
                editable: false,
                valueGetter: (value) => {
                    return value ? new Date(value) : null;
                },
                renderCell: (params) => {
                    return params?.value instanceof Date ? params.value.toLocaleDateString() : '';
                },
            },
            {
                valueOptions: statusOptions.map(o => o.value),//['Active','Upcoming','Complete']
                type: 'singleSelect',
                field: 'status',
                headerName: 'Status',
                flex: .4,
                editable: false,
            },
            {
                field: 'createdBy',
                headerName: 'Created By',
                flex: .5,
                editable: false,
            },
        );

        return columns;

    }, [navigate, nameClick, setDelete, setCancel, statusOptions, hasWriteAccess])

    return isSessionLoading ? (
        <LinearProgress color='secondary' />
    ) : (
        <>
            <DataGridPro
                getDetailPanelContent={({ row }) =>
                    row.surveySessionData && row.surveySessionData.length > 0
                        ? <SurveyTemplateResults surveySessionData={row.surveySessionData} />
                        : null
                }
                getDetailPanelHeight={() => 'auto'} // Height based on the content.
                autoHeight
                slots={{
                    detailPanelExpandIcon: ExpandMoreIcon,
                    detailPanelCollapseIcon: ExpandLessIcon,
                    toolbar: () =>
                        <CustomDataGridToolbar
                            hasWriteAccess={hasWriteAccess}
                            handleCreateNew={() => navigate('wizard/new')}
                        />
                }}
                rows={completedVisible ? surveys : filteredSurveys}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 12, } },
                }}
                loading={isLoading}
                getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 !== 0 ? 'grid-odd' : null}
                pageSizeOptions={[12, 24, 48]}
                disableRowSelectionOnClick
                localeText={{ noRowsLabel: 'No survey templates' }}
            />
            <SurveyTemplateDetailsModal
                isLoading={isLoadingDetails}
                isOpen={isSurveyTemplateDetailsOpen}
                surveyTemplate={selectedSurveyTemplate}
                close={() => setIsSurveyTemplateDetailsOpen(false)}
            />
        </>
    );
}