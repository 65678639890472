// QuestionOptionItem.js
import { Box, FormControlLabel, Radio, Checkbox, IconButton } from '@mui/material';
import { Close, DragIndicator } from '@mui/icons-material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from "@dnd-kit/utilities";

export default function QuestionAnswerOption({ id, option, index, questionTypeId, onDelete, SurveyQuestionTypeEnum }) {

   const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
      id: id,
   });

   const transitionStyle = {
      transform: CSS.Translate.toString(transform),
      transition: transition,
   };

   return (
      <div
         ref={setNodeRef}
         {...attributes}
         style={transitionStyle}
      >
         <Box
            sx={{ display: 'flex', alignItems: 'center' }}
         >
            <Box
               {...listeners}
               sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  cursor: 'grab',
                  color: 'grey.400',
                  mr: 1
               }}
            >
               <DragIndicator />
            </Box>
            <FormControlLabel
               disabled
               value={index}
               control={
                  questionTypeId === SurveyQuestionTypeEnum.MULTIPLE_CHOICE
                     ? <Radio />
                     : <Checkbox />
               }
               label={option.title}
            />
            <IconButton
               size='small'
               color='error'
               onClick={() => onDelete(index)}
            >
               <Close />
            </IconButton>
         </Box>
      </div>
   );
}