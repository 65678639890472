import { Box, Dialog, DialogTitle, IconButton, DialogContent, useTheme, Chip, Skeleton } from '@mui/material';
import { AccessTime, CheckCircleOutline, Close, PlayCircleOutline } from '@mui/icons-material';
import CampaignDetails from './CampaignManagementCommon/CampaignDetails';
import CampaignDetailsSkeleton from './CampaignManagementCommon/CampaignDetailsSkeleton';

function CampaignDetailsModal({ isOpen, close, campaign, isLoading }) {
    const theme = useTheme();

    const statusMap = {
        "Upcoming": {
            icon: <AccessTime />,
            label: "Upcoming",
            color: 'secondary'
        },
        "Active": {
            icon: <PlayCircleOutline />,
            label: "Active",
            color: 'secondary'
        },
        "Completed": {
            icon: <CheckCircleOutline />,
            label: "Completed",
            color: 'success'
        }
    };

    const status = campaign ? statusMap[campaign.status] : null;

    const StatusChip = () => {
        if (!status) {
            return <Skeleton variant='rounded' width={85} height={30} sx={{ borderRadius: '30px' }} />;
        };

        const color = status.color === 'error'
            ? theme.palette[status.color].contrastText
            : theme.palette[status.color].dark;

        return (
            <Chip
                icon={status.icon}
                label={status.label}
                color={status.color}
                sx={{
                    alignSelf: 'flex-end',
                    color: color,
                    backgroundColor: theme.palette[status.color].lighter
                }}
            />
        );
    };

    return (
        <Dialog variant="outlined" open={isOpen} onClose={() => close()} fullWidth maxWidth="md">
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mr: 5 }}>
                    <Box>
                        Campaign Details
                    </Box>
                    <StatusChip />
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={() => { close(); }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: "black",
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {isLoading || !campaign ? (
                    <CampaignDetailsSkeleton />
                ) : (
                    <CampaignDetails campaign={campaign} isLoading={isLoading} />
                )}
            </DialogContent>
        </Dialog>
    );
}

export default CampaignDetailsModal;