import { api } from "../../../app/api";

const TAG = 'CampaignWizard';

export const campaignWizardApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        isCampaignNameInUse: builder.query({
            query: (name) => `campaign/campaign-name-in-use?title=${name}`
        }),
        getCoordinators: builder.query({
            query: () => 'user/coordinators',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        getDistrictSchools: builder.query({
            query: () => 'school/school-buildings',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
    })
})

export const {
    useLazyIsCampaignNameInUseQuery,
    useGetCoordinatorsQuery,
    useGetDistrictSchoolsQuery
} = campaignWizardApi;