import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, LinearProgress, Link, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { Delete, Edit, Group, CopyAllRounded } from '@mui/icons-material';
import { useGetCampaignDetailsMutation } from './campaignManagementSlice';
import { CustomDataGridToolbar } from '../Common/DataGrid/CustomDataGridToolbar';
import CampaignDetailsModal from './CampaignDetailsModal';
import styled from 'styled-components';
import { SystemStatusEnum } from '../../utility/enums';

const CoordinatorBox = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 6px;
    height: 100%;
`;

const ProgressBarBox = styled(Box)`
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const LinearProgressBox = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 10px;
`;

const ProgressBarLabelBox = styled(Box)`
    display: flex;
    justify-content: space-between;
`;

export function CampaignManagementDataGrid({ campaigns, isLoading, isSessionLoading, setDelete, hasWriteAccess, inboundCampaignId }) {
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [isCampaignDetailsOpen, setIsCampaignDetailsOpen] = useState(false);
    const [getCampaignDetailsQuery, { isLoading: isLoadingDetails }] = useGetCampaignDetailsMutation();

    const navigate = useNavigate();

    const nameClick = useCallback((row) => {
        setIsCampaignDetailsOpen(true);
        setSelectedCampaign(null);

        getCampaignDetailsQuery(row.id)
            .unwrap()
            .then(res => {
                setSelectedCampaign(res);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [getCampaignDetailsQuery, setSelectedCampaign]);

    // if the user gets here from notifications, display the details modal
    useEffect(() => {
        if (!inboundCampaignId)
            return;

        // note - strict mode is on, these will appear twice in dev

        getCampaignDetailsQuery(inboundCampaignId)
            .then((response) => {
                if (response.data.systemStatusId === SystemStatusEnum.REMOVED) {
                    toast.error('The selected campaign has been removed and is no longer available.');
                    return;
                }

                if (response.data.status === 'Completed') {
                    toast.error('The selected campaign has expired.');
                    return;
                }

                setSelectedCampaign(response.data);
            })
            .catch((error) => {
                console.error(error);
                toast.error(error.message);
            });
    }, [inboundCampaignId]);

    const renderCoordinatorsCell = (row) => {
        const coordinatorCount = row.coordinators?.length;
        if (coordinatorCount > 1) {
            return (
                <Tooltip
                    arrow
                    title={
                        <List dense>
                            {row.coordinators?.map(coordinator => (
                                <ListItem key={coordinator.id}>
                                    <ListItemText>
                                        {coordinator.firstName} {coordinator.lastName}
                                    </ListItemText>
                                </ListItem>)
                            )}
                        </List>
                    }
                >
                    <CoordinatorBox >
                        <Group />
                        <Box>
                            <Typography variant='body2'>
                                x {coordinatorCount}
                            </Typography>
                        </Box>
                    </CoordinatorBox >
                </Tooltip>
            )
        }
        else {
            return row.coordinators?.map(x => `${x.firstName} ${x.lastName}`);
        }
    };

    const campaignsWithTimelineOrdinal = useMemo(() => {
        const notStarted = campaigns.filter(campaign => campaign.timeline.percentComplete < 0);
        const started = campaigns.filter(campaign => campaign.timeline.percentComplete >= 0);

        notStarted.sort((a, b) => a.timeline.daysUntilStart - b.timeline.daysUntilStart);
        started.sort((a, b) => a.timeline.daysRemaining - b.timeline.daysRemaining);

        const combinedSorted = [...started, ...notStarted];

        return combinedSorted.map((campaign, index) => ({
            ...campaign,
            timeline: {
                ...campaign.timeline,
                ordinal: index
            }
        }));
    }, [campaigns]);

    const columns = useMemo(() => {
        const columns = []

        if (hasWriteAccess) {
            columns.push({
                field: 'actions',
                type: 'actions',
                headerName: 'Action',
                headerAlign: 'center',
                align: 'center',
                minWidth: 135,
                getActions: ({ row }) => {
                    const isDisabled = row.hasActiveSurveyTemplate || false;
                    const isCompleted = (row.timeline.daysRemaining <= 0);
                    return [
                        <Tooltip title={isCompleted ? 'Completed campaigns cannot be edited.' : isDisabled ? 'Campaigns with associated survey templates cannot be edited.' : 'Edit Campaign'} key={`${row.id}-edit-campaign`}>
                            <span>
                                <GridActionsCellItem
                                    icon={<Edit className={isCompleted || isDisabled ? 'action-grey' : 'action-blue'} sx={{ fontSize: 24 }} />}
                                    label={'Edit'}
                                    onClick={() => navigate(`/campaign-management/wizard/${row.id}`)}
                                    disabled={isCompleted || isDisabled}
                                />
                            </span>
                        </Tooltip>,
                        <Tooltip title={isDisabled ? 'Campaigns with associated survey templates cannot be removed.' : 'Remove Campaign'} key={`${row.id}-delete-campaign`}>
                            <span>
                                <GridActionsCellItem
                                    icon={<Delete className={isDisabled ? 'action-grey' : 'action-red'} sx={{ fontSize: 24 }} />}
                                    label={'Delete'}
                                    onClick={() => setDelete(row)}
                                    disabled={isDisabled}
                                />
                            </span>
                        </Tooltip>,
                        <Tooltip title='Copy Campaign' key={`${row.id}-copy-campaign`}>
                            <GridActionsCellItem
                                icon={<CopyAllRounded className='action-blue' sx={{ fontSize: 24 }} />}
                                label={'Copy'}
                                onClick={() => navigate(`/campaign-management/wizard/new/${row.id}`)}
                            />
                        </Tooltip>
                    ];
                }
            });
        }

        columns.push(
            {
                field: 'name',
                headerName: 'Campaign Name',
                flex: .66,
                editable: false,
                renderCell: ({ row }) => (
                    <Link
                        sx={{ cursor: 'pointer' }}
                        onClick={() => nameClick(row)}
                    >
                        {row.name}
                    </Link>
                ),
            },
            {
                field: 'timeline',
                headerName: 'Timeline',
                flex: .5,
                editable: false,
                renderCell: ({ row }) => (
                    <ProgressBarBox>
                        <LinearProgressBox>
                            <LinearProgress
                                color={row.timeline.daysUntilStart > 0 ? 'primary' : 'secondary'}
                                variant='determinate'
                                value={row.timeline.daysUntilStart > 0 ? 0 : (row.timeline.daysRemaining < 0 ? 100 : row.timeline.percentComplete)}
                            />
                            <ProgressBarLabelBox>
                                <Typography variant='caption'>
                                    {row.timeline.daysUntilStart > 0
                                        ? `${row.timeline.daysUntilStart} days until start`
                                        : row.timeline.daysRemaining < 0
                                            ? 'Completed'
                                            : `${Math.min(row.timeline.daysRemaining, 999)} day${row.timeline.daysRemaining === 1 ? '' : 's'} remaining`}
                                </Typography>
                            </ProgressBarLabelBox>
                        </LinearProgressBox>
                    </ProgressBarBox>
                ),
                valueGetter: value => value.ordinal,
            },
            {
                field: 'startDate',
                headerName: 'Start',
                type: 'date',
                flex: 0.4,
                editable: false,
                valueGetter: value => new Date(value),
            },
            {
                field: 'endDate',
                headerName: 'End',
                type: 'date',
                flex: 0.4,
                editable: false,
                valueGetter: value => new Date(value),
            },
            {
                field: 'coordinators',
                headerName: 'Coordinator(s)',
                flex: .5,
                editable: false,
                renderCell: ({ row }) => renderCoordinatorsCell(row),
                valueGetter: (params) => params.map(coordinator => `${coordinator.firstName} ${coordinator.lastName}`).join(', ')
            },
        );

        return columns;
    }, [nameClick, navigate, setDelete, hasWriteAccess]);

    return (
        <>
            {isSessionLoading ? <LinearProgress color='secondary' /> :
                <DataGridPro
                    autoHeight
                    slots={{
                        toolbar: () =>
                            <CustomDataGridToolbar
                                hasWriteAccess={hasWriteAccess}
                                handleCreateNew={() => navigate('wizard/new')}
                            />
                    }}
                    rows={campaignsWithTimelineOrdinal}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 12, } },
                    }}
                    loading={isLoading}
                    getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 !== 0 ? 'grid-odd' : null}
                    pageSizeOptions={[12, 24, 48]}
                    disableRowSelectionOnClick
                    localeText={{ noRowsLabel: 'No campaigns' }}
                />
            }
            <CampaignDetailsModal
                isLoading={isLoadingDetails}
                isOpen={isCampaignDetailsOpen}
                campaign={selectedCampaign}
                close={() => setIsCampaignDetailsOpen(false)}
            />
        </>
    );
}