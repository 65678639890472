import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Stack, Chip } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import InfoBorderBox from '../../Common/Containers/InfoBorderBox';
import CampaignDetailsSkeleton from './CampaignDetailsSkeleton';
import dayjs from 'dayjs';

export default function CampaignDetails({ campaign, isReview, isLoading }) {
    if (isLoading) {
        return <CampaignDetailsSkeleton />;
      }

    const startDate = dayjs(campaign.startDate).format('MM/DD/YYYY')
    const endDate = dayjs(campaign.endDate).format('MM/DD/YYYY')

    return (
        <Box>
            {/* Basic Information Accordion */}
            <Accordion elevation={0} disableGutters defaultExpanded sx={{ backgroundColor: 'inherit' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <strong>Campaign Details</strong>
                </AccordionSummary>
                <AccordionDetails>
                    <InfoBorderBox backgroundColor={isReview ? 'white' : null}>
                        <Stack spacing={2} sx={{ p: 1 }}>
                            <Typography variant='body2'><strong>Name:</strong> {campaign.name}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                                <Typography variant='body2' sx={{ mr: 5 }}><strong>Start Date:</strong> {startDate}</Typography>
                                <Typography variant='body2'><strong>End Date:</strong> {endDate}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                                <Typography variant='body2'><strong>Coordinators:</strong></Typography>
                                {campaign.coordinators.map(coordinator => (
                                    <Chip key={coordinator.id} label={`${coordinator.firstName} ${coordinator.lastName} - ${coordinator.emailAddress}`} size='small' />
                                ))}
                            </Box>
                            <Typography variant='body2'><strong>Objective:</strong> {campaign.objective || campaign.description || '--'}</Typography>
                        </Stack>
                    </InfoBorderBox>
                </AccordionDetails>
            </Accordion>
            {/* District Scope Accordion */}
            <Accordion elevation={0} disableGutters defaultExpanded sx={{ backgroundColor: 'inherit' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <strong>District Scope - Campaign Goals</strong>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2}>
                        {campaign.campaignBenefits.map((goal, i) => (
                            <InfoBorderBox key={`${goal.benefitId} - ${i}`} backgroundColor={isReview ? 'white' : null}>
                                <Stack spacing={2} sx={{ p: 1 }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mr: 5, flexWrap: 'wrap' }}>
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Pillar:
                                            </Typography>
                                            <Chip label={goal.pillar.name} size='small' />
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                                Benefit:
                                            </Typography>
                                            <Chip label={goal.benefit.name} size='small' />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Basis Campaign:
                                        </Typography>
                                        {goal.basisCampaign?.name ?
                                            <Chip label={goal.basisCampaign.name} size='small' /> : '--'}
                                    </Box>
                                    <Typography variant='body2'>
                                        <strong>Target:</strong> {goal.target}%
                                    </Typography>
                                    {/* Schools and School Buildings */}
                                    <Box>
                                        <Typography variant='body2' sx={{ fontWeight: 'bold', mb: 1 }}>
                                            Schools:
                                        </Typography>
                                        <Stack spacing={1}>
                                            {goal.schools.map(school => (
                                                <Box key={`${goal.benefitId} - ${school.id}`} sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                                                    <Typography variant='body2' sx={{ ml: 1 }}>
                                                        {school.name}:
                                                    </Typography>
                                                    {school.schoolBuildings.map(building => (
                                                        <Chip key={`${goal.benefitId} - ${school.id} - ${building.id}`} label={building.name} size='small' />
                                                    ))}
                                                </Box>
                                            ))}
                                        </Stack>
                                    </Box>
                                    <Typography variant='body2'>
                                        <strong>Notes:</strong> {goal.notes || '--'}
                                    </Typography>
                                </Stack>
                            </InfoBorderBox>
                        ))}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Box>
    )
}