import React, { useState } from 'react';
import { Box, Button } from '@mui/material';
import PatchNotesModal, { } from '../Common/PatchNotesModal';

export default function Footer() {
    const [arePatchNotesOpen, setArePatchNotesOpen] = useState(false);

    return (
        <Box
            component='div'
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                padding: '1em'
            }}
        >
            <Button color="pxpFooter" sx={{ fontSize: '12pt' }} onClick={() => setArePatchNotesOpen(true)}>&copy; PillarXP LLC - {process.env.REACT_APP_VERSION}</Button>
            <Box>
                {/* <Link href='/accessibility' underline='always' sx={{ color: '#ffffff', textDecorationColor: '#ffffff', mr: 2 }}>Accessibility</Link>
                <Link href='/privacy-policy' underline='always' sx={{ color: '#ffffff', textDecorationColor: '#ffffff', mr: 2 }}>Privacy Policy</Link>
                <Link href='/terms-of-service' underline='always' sx={{ color: '#ffffff', textDecorationColor: '#ffffff' }}>Terms of Service</Link> */}
            </Box>
            <PatchNotesModal
                open={arePatchNotesOpen}
                onClose={() => setArePatchNotesOpen(false)}
            />
        </Box>
    )
}
