import { api } from '../../app/api';
const TAG = 'CampaignManagement';

export const campaignManagementApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getAllCampaigns: builder.query({
            query: () => 'campaign',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        addCampaign: builder.mutation({
            query: (campaign) => ({
                url: 'campaign',
                method: 'POST',
                body: JSON.stringify(campaign)
            }),
            invalidatesTags: [TAG, {type: 'PillarManagement'}]
        }),
        updateCampaign: builder.mutation({
            query: (campaign) => ({
                url: 'campaign',
                method: 'PUT',
                body: JSON.stringify(campaign)
            }),
            invalidatesTags: [TAG, {type: 'PillarManagement'}]
        }),
        getCampaignForWizard: builder.mutation({
            query: (id) => ({
                url: `campaign/${id}`,
                method: 'GET',
            }),
            invalidatesTags: [TAG]
        }),
        getCampaignDetails: builder.mutation({
            query: (id) => ({
                url: `campaign/${id}/details`,
                method: 'GET',
            }),
            invalidatesTags: [TAG]
        }),
        getCampaignsForDistrictUser: builder.query({
            query: () => 'campaign/by-user-district',
            providesTags: () => [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        deleteCampaign: builder.mutation({
            query: (id) => ({
                url: `campaign/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [TAG, {type: 'PillarManagement'}]
        }),
    })
})

export const {
    useGetAllCampaignsQuery,
    useAddCampaignMutation,
    useUpdateCampaignMutation,
    useGetCampaignForWizardMutation,
    useGetCampaignDetailsMutation,
    useGetCampaignsForDistrictUserQuery,
    useDeleteCampaignMutation,
} = campaignManagementApi;