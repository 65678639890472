import { api } from '../../../app/api';
const TAG = 'PillarManagement';

export const pillarManagementApi = api.injectEndpoints({
    tagTypes: [TAG],
    endpoints: (builder) => ({
        getPillars: builder.query({
            query: () => 'pillar/all',
            providesTags: [{ type: TAG }, { type: 'DistrictSpecific' }]
        }),
        isPillarNameInUse: builder.query({
            query: (name) => `pillar/pillar-name-in-use?title=${name}`,
        }),
        addPillar: builder.mutation({
            query: (pillar) => ({
                url: 'pillar',
                method: 'POST',
                body: JSON.stringify(pillar)
            }),
            invalidatesTags: [{ type: TAG }, { type: 'PillarsWithBenefits' }]
        }),
        updatePillar: builder.mutation({
            query: (pillar) => ({
                url: 'pillar',
                method: 'PUT',
                body: JSON.stringify(pillar)
            }),
            invalidatesTags: [{ type: TAG }, { type: 'PillarsWithBenefits' }]
        }),
        deletePillar: builder.mutation({
            query: (id) => ({
                url: `pillar/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [{ type: TAG }, { type: 'PillarsWithBenefits' }]
        }),
        lockPillar: builder.mutation({
            query: (id) => ({
                url: `pillar/toggle-lock/${id}`,
                method: 'PUT'
            }),
            invalidatesTags: [{ type: TAG }]
        }),
    })
})

export const {
    useGetPillarsQuery,
    useLazyIsPillarNameInUseQuery,
    useAddPillarMutation,
    useUpdatePillarMutation,
    useDeletePillarMutation,
    useLockPillarMutation
} = pillarManagementApi;