import { useState } from 'react';
import { Box, Checkbox, Dialog, DialogTitle, DialogContent, IconButton, Stack, TextField, Typography, Grid, List, ListItem } from '@mui/material';
import { ThumbUp, ThumbDown, Close, ThumbUpOutlined, ThumbDownOutlined } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { ApprovalStatusEnum } from '../../../utility/enums';
import { useUpdateApprovalRequestMutation } from './userManagementSlice';
import DialogActionButtons from '../../Common/Buttons/DialogActionButtons';

export default function ApprovalRequestDialog({ isOpen, close, user }) {

    const [approvalChoice, setApprovalChoice] = useState(null);
    const [approvalNotes, setApprovalNotes] = useState('');
    const [isProcessingRequest, setIsProcessingRequest] = useState(false);
    const [submitRequestLabel, setSubmitRequestLabel] = useState('Confirm');

    const [updateApprovalRequest] = useUpdateApprovalRequestMutation();

    const handleApproveBoxClick = () => {
        if (approvalChoice === ApprovalStatusEnum.DECLINED) {
            setApprovalChoice(prevStatus => prevStatus === ApprovalStatusEnum.DECLINED ? ApprovalStatusEnum.APPROVED : null);
        }
    };

    const handleDeclineBoxClick = () => {
        if (approvalChoice === ApprovalStatusEnum.APPROVED) {
            setApprovalChoice(prevStatus => prevStatus === ApprovalStatusEnum.APPROVED ? ApprovalStatusEnum.DECLINED : null);
        }
    };

    const handleCheckboxClick = (status) => {
        setApprovalChoice(prevStatus => prevStatus === status ? null : status);
    };

    const handleConfirm = () => {
        setIsProcessingRequest(true);
        if (approvalChoice === ApprovalStatusEnum.APPROVED) {
            setSubmitRequestLabel('Approving...');
        }
        else if (approvalChoice === ApprovalStatusEnum.DECLINED) {
            setSubmitRequestLabel('Declining...');
        }

        if (!approvalChoice) {
            reenableSubmit();
            return;
        }

        const pendingApprovalRequest = user.approvalRequests.find(ar => ar.statusId === ApprovalStatusEnum.PENDING)

        if (pendingApprovalRequest == null) {
            toast.error('User does not have a pending approval request');
            reenableSubmit();
            return;
        };

        const approvalRequestDto = {
            id: pendingApprovalRequest.id,
            userId: user.id,
            approvalStatusId: approvalChoice,
            notes: approvalNotes === '' ? null : approvalNotes
        };

        updateApprovalRequest(approvalRequestDto)
            .then(response => {
                if (response?.error) {
                    toast.error('An error occurred when updating the approval request')
                    throw new Error('An error occurred when updating the approval request');
                }
                toast.success('Successfully updated approval request');
                handleClose();
                reenableSubmit();
            })
            .catch(error => {
                console.warn(error);
                toast.error(error);
                reenableSubmit();
            });
    };

    const reenableSubmit = () => {
        setSubmitRequestLabel('Confirm');
        setIsProcessingRequest(false);
    };

    const reset = () => {
        setApprovalNotes('');
        setApprovalChoice(null);
    };

    const handleClose = () => {
        reset();
        close();
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'sm'}>
            <DialogTitle>
                <Box>
                    User Account Request
                </Box>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ mt: '.3em' }}>
                <Stack spacing={3}>
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='body1' align='left' sx={{ fontWeight: 600 }}>
                                {user?.firstName} {user?.lastName}
                            </Typography>
                            <Typography variant='body2' align='left'>
                                {user?.emailAddress}
                            </Typography>
                            <Box>
                                {user?.schools.map(school => (
                                    <Box key={school.id} sx={{ mt: 1 }}>
                                        <Typography variant='body2' align='left'>
                                            {school.name}
                                        </Typography>
                                        {school.schoolBuildings && school.schoolBuildings.length > 0 && (
                                            <List disablePadding>
                                                {school.schoolBuildings.map(building => (
                                                    <ListItem key={building.id} disablePadding sx={{ pl: 0 }}>
                                                        <Typography variant='body2' align='left' sx={{ ml: 1 }}>
                                                            {building.name}
                                                        </Typography>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <Typography variant='body1' align='left' sx={{ fontWeight: 600 }}>
                                Requested System Role:
                            </Typography>
                            <Typography variant='body2' align='left'>
                                {user?.roles.map(role => role.name).join(', ')}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: approvalChoice === ApprovalStatusEnum.APPROVED ? 'green' :
                                approvalChoice === ApprovalStatusEnum.DECLINED ? 'lightgrey' : null
                        }}>
                            <Box onClick={handleApproveBoxClick} sx={{ cursor: 'pointer' }}>
                                <Checkbox
                                    icon={<ThumbUpOutlined sx={{ fontSize: '36px' }} />}
                                    checkedIcon={<ThumbUp sx={{ fontSize: '36px' }} />}
                                    color='success'
                                    aria-label='approve'
                                    checked={approvalChoice === ApprovalStatusEnum.APPROVED}
                                    onChange={() => handleCheckboxClick(ApprovalStatusEnum.APPROVED)}
                                    disabled={approvalChoice === ApprovalStatusEnum.DECLINED}
                                />
                            </Box>
                            <Typography variant='caption' alignSelf='center'>
                                Approve
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            color: approvalChoice === ApprovalStatusEnum.DECLINED ? 'red' :
                                approvalChoice === ApprovalStatusEnum.APPROVED ? 'lightgrey' : null
                        }}>
                            <Box onClick={handleDeclineBoxClick} sx={{ cursor: 'pointer' }}>
                                <Checkbox
                                    icon={<ThumbDownOutlined sx={{ fontSize: '36px' }} />}
                                    checkedIcon={<ThumbDown sx={{ fontSize: '36px' }} />}
                                    color='error'
                                    aria-label='deny'
                                    checked={approvalChoice === ApprovalStatusEnum.DECLINED}
                                    onChange={() => handleCheckboxClick(ApprovalStatusEnum.DECLINED)}
                                    disabled={approvalChoice === ApprovalStatusEnum.APPROVED}
                                />
                            </Box>
                            <Typography variant='caption' alignSelf='center'>
                                Deny
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <TextField
                            id='approval-request-notes-textfield'
                            label='Notes'
                            variant='outlined'
                            multiline
                            size='small'
                            rows={4}
                            fullWidth
                            onChange={(e) => setApprovalNotes(e.target.value)}
                        />
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActionButtons
                fullWidth
                closeText='Cancel'
                submitText={submitRequestLabel}
                onSubmit={handleConfirm}
                onClose={handleClose}
                isSubmitDisabled={isProcessingRequest} 
            />
        </Dialog>
    );
}