// BenefitFormModal.js
import { Dialog, DialogTitle, DialogContent, TextField, Button, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import DialogActionButtons from '../../Common/Buttons/DialogActionButtons';

const SchoolBuildingFormModal = ({ isOpen, onClose, buildingForm, handleBuildingFormChange, handleBuildingUpdate, isEditingBuilding, formErrors }) => {
   return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
         <DialogTitle>
            {isEditingBuilding ? 'Edit Building' : 'Add Building'}
            <Button
               aria-label="close"
               onClick={onClose}
               sx={{ position: 'absolute', right: 8, top: 8, color: 'grey.500' }}
            >
               <Close />
            </Button>
         </DialogTitle>
         <DialogContent>
            <Stack spacing={3} sx={{ pt: 1 }}>
               <TextField
                  id="building-name"
                  label="Building Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  required
                  value={buildingForm?.name}
                  error={!!formErrors?.buildingName}
                  helperText={formErrors?.buildingName}
                  onChange={(e) => handleBuildingFormChange('name', e.target.value.slice(0, 100))}
                  sx={{ mb: 2 }}
               />
            </Stack>
         </DialogContent>
         <DialogActionButtons
            fullWidth
            closeText="Cancel"
            onClose={onClose}
            submitText={isEditingBuilding ? 'Update' : 'Add'}
            onSubmit={handleBuildingUpdate}
         />
      </Dialog>
   );
};

export default SchoolBuildingFormModal;