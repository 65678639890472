import { useMemo } from 'react';
import { Accordion, AccordionSummary, Box, Chip, Link, Stack, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import InfoBorderBox from '../../Common/Containers/InfoBorderBox';
import SurveyTemplateDetailsSkeleton from './SurveyTemplateDetailsSkeleton';
import { generateScheduleStatement, parseCronValue } from '../../../utility/cron';

export default function SurveyTemplateDetails({ details, openSurveyPreview, isReview, isLoading }) {
    const scheduleStatement = useMemo(() => {
        if (!details.cronSchedule) return null;
        const { recurrencePattern, weekDays } = parseCronValue(details.cronSchedule);

        return generateScheduleStatement(details.cronSchedule, recurrencePattern, weekDays, details.startDate, details.endDate);
    }, [details.cronSchedule, details.startDate, details.endDate]);

    if (isLoading) {
        return <SurveyTemplateDetailsSkeleton />;
    };

    return (
        <Box>
            {/* Setup Accordion */}
            <Accordion elevation={0} disableGutters defaultExpanded sx={{ backgroundColor: 'inherit' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <strong>Setup</strong>
                </AccordionSummary>
                <InfoBorderBox sx={{ mx: 2 }} backgroundColor={isReview ? 'white' : null}>
                    <Stack spacing={2} sx={{ p: 1 }}>
                        <Typography variant='body2'>
                            <strong>Name:</strong> {details.title}
                        </Typography>
                    </Stack>
                </InfoBorderBox>
            </Accordion>
            {/* Campaign, Pillars and Benefits Accordion */}
            <Accordion elevation={0} disableGutters defaultExpanded sx={{ backgroundColor: 'inherit' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <strong>Campaign, Pillar and Benefits</strong>
                </AccordionSummary>
                <InfoBorderBox sx={{ mx: 2 }} backgroundColor={isReview ? 'white' : null}>
                    <Stack spacing={2} sx={{ p: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                Campaign:
                            </Typography>
                            <Chip size='small' label={details.campaign.name} />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                Pillar:
                            </Typography>
                            {details.pillars[0]?.name &&
                                <Chip size='small' label={details.pillars[0].name} />
                            }
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                Benefits:
                            </Typography>
                            {details.pillars[0]?.benefits.map(benefit => (
                                <Chip size='small' key={benefit.id} label={benefit.name} />
                            ))}
                        </Box>
                    </Stack>
                </InfoBorderBox>
            </Accordion>
            {/* Question Builder Accordion */}
            <Accordion elevation={0} disableGutters defaultExpanded sx={{ backgroundColor: 'inherit' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <strong>Question Builder</strong>
                </AccordionSummary>
                <InfoBorderBox sx={{ mx: 2 }} backgroundColor={isReview ? 'white' : null}>
                    <Stack spacing={2} sx={{ p: 1 }}>
                        <Link
                            component='button'
                            align='left'
                            onClick={openSurveyPreview}
                        >
                            <Typography variant='body2'>
                                Preview Survey
                            </Typography>
                        </Link>
                    </Stack>
                </InfoBorderBox>
            </Accordion>
            {/* Distribution Group and Recurrence Schedule Accordion */}
            <Accordion elevation={0} disableGutters defaultExpanded sx={{ backgroundColor: 'inherit' }}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <strong>Distribution Group and Recurrence Schedule</strong>
                </AccordionSummary>
                <Stack spacing={2} sx={{ mx: 2 }}>
                    <InfoBorderBox backgroundColor={isReview ? 'white' : null}>
                        <Stack spacing={2} sx={{ p: 1 }}>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                Distribution Group:
                            </Typography>
                            <Box>
                                <Typography variant='body2' sx={{ fontWeight: 'bold', mb: 1 }}>
                                    Schools:
                                </Typography>
                                <Stack spacing={1}>
                                    {details.schools.map(school => (
                                        <Box key={school.name} sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                                            <Typography variant='body2' sx={{ ml: 1 }}>
                                                {school.name}:
                                            </Typography>
                                            {school.schoolBuildings.map(building => (
                                                <Chip size='small' key={building.name} label={building.name} />
                                            ))}
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                    Roles:
                                </Typography>
                                {details.roles.map(role => (
                                    <Chip size='small' key={role.id} label={role.value} />
                                ))}
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                                <Typography variant='body2' sx={{ mr: 5 }}>
                                    <strong>Minutes to Complete:</strong> {details.minutesToComplete}
                                </Typography>
                                <Typography variant='body2'>
                                    <strong>Sample Size:</strong> {details.sampleSize}
                                </Typography>
                            </Box>
                            <Typography variant='body2'>
                                <strong>Rotation:</strong> {details.rotation}
                            </Typography>
                        </Stack>
                    </InfoBorderBox>
                    <InfoBorderBox backgroundColor={isReview ? 'white' : null}>
                        <Stack spacing={2} sx={{ p: 1 }}>
                            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                                Recurrence Schedule:
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <Typography variant='body2'>{scheduleStatement}</Typography>
                            </Box>
                            {details?.surveySessions?.length && (
                                <>
                                    <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                        Session Schedule:
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 7 }}>
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Status
                                        </Typography>
                                        <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                                            Send Date
                                        </Typography>
                                    </Box>
                                    {details.surveySessions.map(x => (
                                        <Box key={`session-${x.id}`} sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                            <Typography variant='body2'>
                                                {x.statusName}
                                            </Typography>
                                            <Typography variant='body2'>
                                                {new Date(x.sendDate).toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                    ))}
                                </>
                            )}
                        </Stack>
                    </InfoBorderBox>
                </Stack>
            </Accordion>
        </Box>
    )
}