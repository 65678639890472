import { useState, useMemo } from 'react';
import { useGetAllUsersQuery, useLockUserMutation, useDeleteUserMutation } from './userManagementSlice';
import { useGetSchoolsWithBuildingsQuery } from "../../../app/slices/schoolsSlice";
import { toast } from "react-toastify";
import UserManagementDataGrid from "./UserManagementDataGrid";
import UserManagementFilters from "./UserManagementFilters";
import ConfirmationModal from '../../Common/ConfirmationModal';
import ApprovalRequestDialog from './ApprovalRequestDialog';
import { SystemStatusEnum } from '../../../utility/enums';
import UserFormModal from './UserFormModal';

const defaultFilters = {
    schoolBuildingIds: [],
    status: null,
    hideRemoved: true,
};

function UserManagementContainer() {
    const [deletingUser, setDeletingUser] = useState(null);
    const [editingUser, setEditingUser] = useState(null);
    const [lockingUser, setLockingUser] = useState(null);
    const [approvalUser, setApprovalUser] = useState(null);
    const [filters, setFilters] = useState({ ...defaultFilters });

    const [lockUser] = useLockUserMutation();
    const [deleteUser] = useDeleteUserMutation();

    const { data: users = [], isLoading } = useGetAllUsersQuery();

    const filteredUsers = useMemo(() => {
        return users?.filter(u => {
            if (filters.hideRemoved && u.systemStatusId === SystemStatusEnum.REMOVED) return false;
            if (filters.schoolBuildingIds.length > 0) {
                const userSchoolBuildings = u.schools.flatMap(school => school.schoolBuildings || []);
                const buildingMatch = userSchoolBuildings.some(building => filters.schoolBuildingIds.includes(building.id));
                if (!buildingMatch) return false;
            }
            if (filters.status?.id && u.systemStatusId !== filters.status.id) return false;

            return true;
        })
    }, [users, filters]);

    const { data: schools = [] } = useGetSchoolsWithBuildingsQuery();

    function onDeleteUser(id) {
        deleteUser(id)
            .then((response) => {
                const isDeleting = response?.data?.isDeleting;

                if (isDeleting === null)
                    toast.error(`Failed to remove user`);

                setDeletingUser(null);
                toast.success('Successfully removed user');
            })
            .catch((error) => {
                toast.error(error);
                setDeletingUser(null);
            });
    }

    function onLockUser(id) {
        lockUser(id)
            .then((response) => {
                const isLocked = response?.data?.isLocked;

                if (isLocked === null)
                    toast.error(`Failed to unlock/lock user`);

                setLockingUser(null);
                toast.success(`Successfully ${isLocked ? 'locked user' : 'unlocked user and set to active'}`);
            })
            .catch(error => {
                toast.error(error);
                setLockingUser(null);
            })
    }

    return (
        <>
            <UserManagementFilters
                filters={filters}
                setFilters={setFilters}
                schools={schools}
            />
            <UserManagementDataGrid
                users={filteredUsers}
                isLoading={isLoading}
                setDelete={setDeletingUser}
                setEdit={setEditingUser}
                setLock={setLockingUser}
                setEditApproval={setApprovalUser}
            />
            <UserFormModal
                isOpen={!!editingUser}
                user={editingUser}
                close={() => setEditingUser(null)}
                schools={schools}
            />
            <ConfirmationModal
                isOpen={!!deletingUser}
                close={() => setDeletingUser(null)}
                onConfirm={() => onDeleteUser(deletingUser.id)}
                title="Remove User?"
                message="Please confirm you'd like to remove the selected user."
                saveBtnText="Remove"
            />
            <ConfirmationModal
                isOpen={!!lockingUser}
                close={() => setLockingUser(null)}
                onConfirm={() => onLockUser(lockingUser.id)}
                title={lockingUser?.isLocked ? "Unlock User Account?" : "Lock User Account?"}
                message={lockingUser?.isLocked ? "Please confirm you'd like to unlock the selected user." : "Please confirm you'd like to lock the selected user."}
                saveBtnText={lockingUser?.isLocked ? "Unlock" : "Lock"}
            />
            <ApprovalRequestDialog
                isOpen={!!approvalUser}
                user={approvalUser}
                close={() => setApprovalUser(null)}
            />
        </>
    );
};

export default UserManagementContainer;