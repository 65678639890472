import { useState, useMemo } from 'react';
import { useGetAllSchoolsQuery, useLockSchoolMutation, useDeleteSchoolMutation } from './schoolManagementSlice';
import { useGetGradeLevelsQuery } from '../../../app/slices/gradeLevelsSlice';
import { toast } from "react-toastify";
import SchoolManagementDataGrid from "./SchoolManagementDataGrid";
import SchoolManagementFilters from "./SchoolManagementFilters";
import ConfirmationModal from '../../Common/ConfirmationModal';
import SchoolFormModal from './SchoolFormModal';

const defaultFilters = {
    name: null,
    code: null,
};

function SchoolManagementContainer() {
    const [deletingSchool, setDeletingSchool] = useState(null);
    const [editingSchool, setEditingSchool] = useState(null);
    const [lockingSchool, setLockingSchool] = useState(null);
    const [filters, setFilters] = useState({ ...defaultFilters });

    const [lockSchool] = useLockSchoolMutation();
    const [deleteSchool] = useDeleteSchoolMutation();

    const { data: gradeLevels = [] } = useGetGradeLevelsQuery();

    const { data: schools = [], isLoading } = useGetAllSchoolsQuery();
    const filteredSchools = useMemo(() => {
        return schools?.filter(s => {
            if (filters.name?.id && s.id !== filters.name.id) return false
            if (filters.code?.id && s.id !== filters.code.id) return false;

            return true;
        })
    }, [schools, filters]);

    const mappedSchoolNames = useMemo(() => schools.map(x => ({ id: x.id, value: x.name })), [schools]);
    const mappedSchoolCodes = useMemo(() => schools.map(x => ({ id: x.id, value: x.code })), [schools]);

    function onDeleteSchool(id) {
        deleteSchool(id)
            .then(() => {
                setDeletingSchool(null);
                toast.success('Successfully removed school');
            })
            .catch((error) => {
                toast.error(error);
                setDeletingSchool(null);
            });
    }

    function onLockSchool(id) {
        lockSchool(id)
            .then((response) => {
                const isLocked = response?.data?.isLocked;

                if (isLocked === null)
                    toast.error(`Failed to unlock/lock school`);

                setLockingSchool(null);
                toast.success(`Successfully ${isLocked ? 'locked school' : 'unlocked school and set to active'}`);
            })
            .catch(error => {
                toast.error(error);
                setLockingSchool(null);
            })
    }

    return (
        <>
            <SchoolManagementFilters
                filters={filters}
                setFilters={setFilters}
                schools={mappedSchoolNames}
                codes={mappedSchoolCodes}
            />
            <SchoolManagementDataGrid
                schools={filteredSchools}
                gradeLevels={gradeLevels}
                isLoading={isLoading}
                setDelete={setDeletingSchool}
                setEdit={setEditingSchool}
                setLock={setLockingSchool}
            />
            <SchoolFormModal
                isOpen={!!editingSchool}
                school={editingSchool}
                close={() => setEditingSchool(null)}
                gradeLevels={gradeLevels}
            />
            <ConfirmationModal
                isOpen={!!deletingSchool}
                close={() => setDeletingSchool(null)}
                onConfirm={() => onDeleteSchool(deletingSchool.id)}
                title="Remove School?"
                message="Please confirm you'd like to remove the selected school."
                saveBtnText="Remove"
            />
            <ConfirmationModal
                isOpen={!!lockingSchool}
                close={() => setLockingSchool(null)}
                onConfirm={() => onLockSchool(lockingSchool.id)}
                title={lockingSchool?.isLocked ? "Unlock School?" : "Lock School?"}
                message={lockingSchool?.isLocked ? "Please confirm you'd like to unlock the selected school." : "Please confirm you'd like to lock the selected school."}
                saveBtnText={lockingSchool?.isLocked ? "Unlock" : "Lock"}
            />
        </>
    );
};

export default SchoolManagementContainer;