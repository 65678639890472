import { Stack, Skeleton, Box, Paper } from '@mui/material';
import InfoBorderBox from '../../Common/Containers/InfoBorderBox';

export default function CampaignDetailsSkeleton() {
   return (
      <Box>
         {/* Campaign Details Skeleton */}
         <Paper elevation={0} sx={{ mx: 2, mb: 2, mt: 1.3 }}>
            <Skeleton variant="text" width={150} height={31} sx={{ mb: 2 }} />
            <InfoBorderBox sx={{ my: 2 }}>
               <Stack spacing={1} sx={{ p: 1 }}>
                  <Skeleton variant="text" width="30%" height={30} />
                  <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                     <Skeleton variant="text" width="25%" height={30} />
                     <Skeleton variant="text" width="25%" height={30} />
                  </Box>
                  <Skeleton variant="text" width="60%" height={30} />
                  <Skeleton variant="text" width="100%" height={75} />
               </Stack>
            </InfoBorderBox>
         </Paper>

         {/* District Scope Skeleton */}
         <Paper elevation={0} sx={{ mx: 2, mb: 2, mt: 3 }}>
            <Skeleton variant="text" width={275} height={31} sx={{ mb: 2 }} />
            {Array.from({ length: 3 }).map((_, i) => (
               <InfoBorderBox sx={{ my: 2 }} key={i}>
                  <Stack spacing={1} sx={{ p: 1 }}>
                     <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                        <Skeleton variant="text" width="25%" height={30} />
                        <Skeleton variant="text" width="25%" height={30} />
                     </Box>
                     <Skeleton variant="text" width="60%" height={30} />
                     <Skeleton variant="text" width="15%" height={30} />
                     <Skeleton variant="text" width="75%" height={30} />
                     <Skeleton variant="text" width="100%" height={60} />
                  </Stack>
               </InfoBorderBox>
            ))}
         </Paper>
      </Box>
   );
}