import { useEffect, useState } from "react";
import { Box} from '@mui/material';
import { useGetSystemContactByDistrictIdQuery } from '../../app/slices/systemContactSlice';

export default function UserAgreementDoc({ districtId }) {
    const [userAgreement, setUserAgreement] = useState();

    const { data: contactInfo} = useGetSystemContactByDistrictIdQuery(districtId, { skip: !districtId });

    useEffect(() => {
        const rawUserAgreement = `
<p><strong>BETA</strong>​ ​<strong>TESTING</strong>​ ​<strong>SOFTWARE</strong>​ ​<strong>LICENSE</strong>​ ​<strong>AGREEMENT</strong></p>
<p>This Beta Testing Software License Agreement (this "​<strong>Agreement</strong>​"), made as of the date that the Licensee accepts the terms of this Agreement (the "​<strong>Effective Date</strong>​"), is entered into by and between PillarXP, LLC, a Pennsylvania limited liability company, with a business address of 1144 Kennebec Drive, Chambersburg, Pennsylvania, 17201 (hereafter the "​<strong>Licensor</strong>​") and [School System] (hereafter the "<strong>Licensee</strong>​"). Licensor and Licensee may be referred​ ​to​ ​herein​ ​collectively​ ​as​ ​the​ ​"​<strong>Parties</strong>​"​ ​or​ ​individually​ ​as​ ​a​ ​"​<strong>Party</strong>​."</p>
<p><strong>WHEREAS</strong>, Licensor has developed proprietary software known as PillarXP. Licensor intends to launch PillarXP on the commercial software market in a format known as Software as a Service (hereafter “<strong>SaaS</strong>”).</p>
<p><strong>WHEREAS</strong>, Licensor desires that the software be tested in a live environment prior to its general release, and is willing to offer the software to certain select prospective Licensees, in its intended market demographic, at a commercial discount rate, in exchange for Licensee’s feedback regarding the software’s performance in a live software environment (hereafter the “<strong>Beta Software</strong>”).</p>
<p><strong>WHEREAS</strong>, Licensee wishes to serve as a beta tester of the Beta Software.</p>
<p><strong>NOW</strong>, <strong>THEREFORE</strong>, in consideration of the mutual covenants, terms, and conditions set forth herein, and for other good and valuable consideration, the receipt and sufficiency of which are hereby acknowledged, the​ ​Parties​ ​agree​ ​as​ ​follows:</p>
<p>1. ​<strong><u>License</u></strong>​.</p>
<ol type="a">
<li><p><u>License Grant</u>​. Subject to terms and conditions of this Agreement, Licensor hereby grants to Licensee a limited, revocable, non-exclusive, and non-transferable single license, during the Term,​ ​to​ ​use​ ​the​ ​Beta Software​ ​for​ ​beta​ ​testing​ ​and​ ​evaluation​ ​during​ ​the​ ​Term.</p></li>
<li><p><u>Use Restrictions</u>​. Licensee shall not use the Beta Software for any purposes beyond the scope of the license granted in this Agreement. Without limiting the foregoing and except as otherwise expressly set forth in this Agreement, Licensee shall not at any time, directly or indirectly: (i) copy, modify, or create derivative works of the Beta Software, in whole or in part, for the purpose of developing competing products or technologies or to assist a third party in such activities; (ii) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise allow any third-party to use or examine the Beta Software in any way without the express written permission of Licensor; (iii) reverse engineer, disassemble, decompile, decode, or otherwise attempt to gain access to the source code of the Beta Software, in whole or in part; (iv) remove or alter any copyright or proprietary notices from the Beta Software; (v) use the Beta Software in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or ​any​ other ​applicable​ ​law, or (vi) bypass, disable or circumvent any technical limitation, encryption, security, digital rights management, or authentication mechanism in the Beta Software</p></li>
<li><p><u>Reservation of Rights</u>.​ Licensor reserves all rights not expressly granted to Licensee in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Licensee or any third party, any intellectual​ ​property​ ​rights​ ​or​ ​other​ ​right,​ ​title,​ ​or​ ​interest​ ​in​ ​or​ ​to​ ​the​ ​Beta Software.</p></li>
<li><p><u>Delivery​</u>. The Beta Software will be accessible via the Internet and Licensor will deliver the Beta Software to Licensee by providing Licensee login credentials that are unique to Licensee.</p></li>
</ol>
<p>2. <strong><u>Licensee Responsibilities</u></strong>​.</p>
<p>Licensee is responsible and liable for all uses of the Beta Software resulting from access provided by Licensee, directly or indirectly, whether such access or use is permitted by or in violation of this Agreement. Without limiting the foregoing, Licensee is responsible for all acts and omissions of all employees or agents of Licensee who Licensee permits to access and use the Beta Software pursuant to Licensee's license hereunder (any, ​“<strong>Authorized User</strong>”<strong>​</strong>), and any act or omission by an Authorized User that would constitute a breach of this Agreement if taken by Licensee will be deemed a breach of this Agreement by Licensee. Licensee shall take reasonable efforts to make all Authorized Users aware of this Agreement and shall cause Authorized​ ​Users​ ​to​ ​comply​ ​with​ ​such​ ​provisions.</p>
<p>3. <strong><u>Confidential Information</u></strong>.</p>
<ol type="a">
<li><p>Licensee agrees that the Beta Software, and any Beta Software documentation provided to Licensee by Licensor, including the source and object codes for said Beta Software, constitutes confidential proprietary information of Licensor. Licensee agrees not to transfer, copy, disclose, provide, or otherwise make available such confidential information in any form to any third party without the prior written consent of Licensor. Licensee agrees to implement reasonable security measures to protect such confidential information, but without limitation to the foregoing, shall use best efforts to maintain the security of the Beta Software provided to Licensee by Licensor. Licensee will use its best efforts to cooperate with and assist Licensor in identifying and preventing any unauthorized use, copying, transfer, or disclosure of the Beta Software, or any portion thereof.</p></li>
<li><p>This Beta Software and any documentation associated therewith, shall be accessible only to Licensees who are invited to test the Beta Software and provide feedback to Licensor. All information available concerning the Beta Software is confidential information of Licensor. For a period of ten (10) years from the time Licensee accessed this confidential information, Licensee may not disclose this confidential information to any third party without the written consent of Licensor. This restriction will not apply to any information that: (i) is or becomes publicly available without a breach of this restriction; (ii) was lawfully known by a third-party under no duty of confidentiality to Licensor; or (iii) was independently developed by a recipient without the use of or reliance on Licensor’s confidential information. Licensee may disclose confidential information if Licensee is required to disclose such confidential information by virtue of a law or court order that requires disclosure, provided that Licensee provides Licensor reasonable notice thereof and gives Licensor a reasonable opportunity to oppose and/or attempt to limit such production, unless the law or court order prohibits giving such notice.</p></li>
</ol>
<p>4. <strong><u>Intellectual​ ​Property​ ​Ownership;​ ​Feedback</u></strong>​.</p>
<ol type="a">
<li><p><u>Ownership​ of Intellectual Property</u>. Licensee acknowledges that, as between Licensor and Licensee, the Beta Software is copyrighted to Licensor and Licensor is the sole owner of ​all​ ​right,​ ​title,​ ​and​ ​interest,​ ​including​ ​all​ ​intellectual​ ​property​ ​rights,​ ​in​ ​and​ ​to​ ​the​ ​Beta Software.</p></li>
<li><p><u>Licensee Data</u>. Licensor acknowledges that, as between Licensor and Licensee, Licensee owns all right, title, and interest, including all intellectual property rights, in and to any Licensee Data that Licensee uploads or incorporates into the Beta Software. Licensee hereby grants to Licensor a non-exclusive, royalty-free, worldwide license to use and display the Licensee Data and perform all acts that may be necessary for the Beta Software to perform its intended functions. Licensee grants Licensor the perpetual right to use the Licensee Data in aggregated and anonymized form for purposes of analyzing, improving or otherwise evaluating and further developing the Beta Software or any of Licensor’s products and services.</p></li>
</ol>
<blockquote>
<p>(b) <u>Feedback</u>​. If Licensee or any of its employees, contractors, or agents sends or transmits any communications or materials to Licensor by mail, email, telephone, or otherwise, suggesting or recommending changes to the Beta Software, including without limitation, new features, bug fixes, potential solutions, visual improvements, software navigation recommendations, or functionality relating thereto, or any comments, questions, suggestions, or the like ("​<strong>Feedback</strong>​"), Licensor is free to use such Feedback irrespective of any other obligation or limitation between the Parties governing such Feedback. Licensee hereby assigns on its behalf, and on behalf of its employees, contractors and/or agents, all right, title, and interest in, and Licensor is free to use, without any attribution or compensation to any party, any ideas, know-how, concepts, techniques, or other intellectual property rights contained in the Feedback, for any purpose whatsoever, although Licensor is not required to use any Feedback. In particular, unless otherwise specified in writing to Licensor, Licensor shall be free to disclose and use all Feedback in press releases, Licensee​ ​testimonials,​ ​and​ ​as​ ​a​ ​reference​ ​in​ ​marketing​ ​and​ ​sales​ ​initiatives​ ​by​ ​Licensor.</p>
</blockquote>
<p>5. <strong><u>Warranty​ ​Disclaimer;​ ​Assumption​ ​of​ ​Risk;​ ​Acknowledgements</u></strong>​.</p>
<ol type="a">
<li><p>The Parties acknowledge that Licensor is providing Licensee with access to the Beta Software before Licensor considers the Beta Software to be ready to be offered on the commercial market as a commercial Beta Software SaaS product offering. As such, the Beta Software may not operate correctly, it may not be fully functional, and it may contain errors or design flaws that causes the Beta Software to not perform as expected. The entire risk arising out of the use or performance of the Beta Software is assumed by and remains solely with Licensee.</p></li>
<li><p>Licensee acknowledges and agrees that the limitations of liability and waivers in favor of Licensor contained in this Agreement are a material part of this Agreement and that Licensor would not be willing to provide the Beta Software to Licensee for evaluation and testing under this Agreement. Notwithstanding the foregoing, Licensee acknowledges and agrees that Licensee will not be owed any fees or other compensation for the Feedback provided to Licensor, and that Licensor may choose, in its sole discretion, for any reason or no reason, not to ultimately incorporate or reflect any Feedback provided hereunder or to commercially release the Beta Software at a price or on terms acceptable to Licensee, or at all.</p></li>
<li><p><strong>THE BETA SOFTWARE IS PROVIDED BY LICENSOR TO LICENSEE “AS-IS” WITHOUT A WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, LICENSOR DISCLAIMS ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, RELATING TO OR ARISING UNDER THIS AGREEMENT, INCLUDING, WITHOUT LIMITATION, THE WARRANTIES OF TITLE, NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, USAGE OF TRADE, AND COURSE OF DEALING. WITHOUT LIMITING THE FOREGOING, LICENSOR DOES NOT WARRANT THAT: (i) THE BETA SOFTWARE WILL MEET LICENSEE’S REQUIREMENTS, (ii) OPERATE WITHOUT INTERRUPTION, (iii) BE COMPATIBLE OR WORK WITH LICENSEE’S EXISTING SOFTWARE OR NETWORK INFRASTRUCTURE, (iv) THAT THE BETA SOFTWARE IS FREE OF VIRUSES, BUGS, HARMFUL COMPONENTS, OR WILL PERFORM ERROR FREE. NO INFORMATION OR ADVICE GIVEN BY LICENSOR WILL IN ANY WAY INCREASE THE SCOPE OF ANY WARRANTY PROVIDED IN THIS AGREEMENT. LICENSOR DOES NOT WARRANT OR ASSUME RESPOSNIBILITY FOR THE ACCURACY OR COMPLETENESS OF ANY THIRD-PARTY INFORMATION, TEXT, GRAPHICS, OR LINKS CONTAINED IN THE BETA SOFTWARE</strong></p></li>
</ol>
<p>6. <strong><u>Limitations of Liability</u></strong>​. ​</p>
<ol type="a">
<li><p><strong>IN NO EVENT WILL LICENSOR BE LIABLE TO LICENSEE OR ANY PARTY FOR, WITHOUT LIMITATION, ANY LOSS OF USE, INTERRUPTION OF BUSINESS, OR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY, OR PUNITIVE OR CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT PRODUCT LIABILITY OR OTHERWISE, WHETHER ARISING OUT OF THE USE OR INABILITY TO USE THE LICENSED BETA SOFTWARE (INCLUDING BUT NOT LIMITED TO LOSS OF DATA OR DATA BEING RENDERED INACCURATE OR LOSSES SUSTAINED BY LICENSEE OR THIRD PARTIES OR A FAILURE OF THE LICENSED BETA SOFTWARE TO OPERATE WITH ANY OTHER PROGRAMS).</strong></p></li>
<li><p><strong><u>DAMAGES CAP</u>. IF A COURT OF COMPETENT JURISDICTION DETERMINES THAT THE FOREGOING WARRANTY DISCLAIMER, USE WAIVERS, OR LIABILITY LIMITATIONS ARE NOT ENFORCEABLE, AND THAT LICENSOR IS LIABLE TO LICENSEE FOR MONETARY DAMAGES, UNDER ANY LEGAL THEORY, REGARDLESS OF THE WARRANTY DISCLAIMER, WAIVERS, OR LIABILITY LIMITATION SET FORTH HEREIN, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, LICENSOR’S TOTAL AGGREGATE LIABILITY TO LICENSEE FOR ANY AND ALL LIABILITIES OR CLAIMS ARISING OUT OF OR RELATED TO LICENSEE’S USE OF THE BETA SOFTWARE WILL NOT EXCEED THE FEE PAID BY LICENSEE TO LICENSOR FOR LICENSEE’S ACCESS TO AND THE USE OF THE BETA SOFTWARE WHILE THE BETA SOFTWARE IS IN BETA FORMAT.</strong></p></li>
</ol>
<p><strong>7. <u>Term​ ​and​ ​Termination</u>​.</strong></p>
<blockquote>
<p>(a) <u>Term</u>​. The term of this Agreement begins on the Effective Date and, unless terminated earlier pursuant to any of the Agreement's express provisions, or unless extended by mutual written consent, will​ ​continue​ ​in​ ​effect​ ​until ​such time as Licensor informs Licensee that the Beta Software testing is complete ​(the​ ​"<strong>Term</strong>"). At that point, Licensor will offer Licensee an opportunity to subscribe to the PillarXP software under a SaaS.</p>
</blockquote>
<ol start="3" type="a">
<li><p><u>Termination</u>​. In addition to any other express termination right set forth herein: (i) Licensor or Licensee may terminate this Agreement at any time for any reason or​ ​no​ ​reason; (ii) Licensor may terminate this Agreement, effective immediately upon written notice to the Licensee, if the Licensee: (A) becomes insolvent or is generally unable to pay, or fails to pay, its debts as they become due; (B) files or has filed against it, a petition for voluntary or involuntary bankruptcy or otherwise becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or insolvency law; (C) makes or seeks to make a general assignment for the benefit of its creditors; or (D) applies for or has appointed a receiver, trustee, custodian, or similar agent appointed by order of any court​ ​of​ ​competent​ ​jurisdiction​ ​to​ ​take​ ​charge​ ​of​ ​or​ ​sell​ ​any​ ​material​ ​portion​ ​of​ ​its​ ​property​ ​or​ ​business.</p></li>
<li><p><u>Effect of Expiration or Termination</u>​. Upon expiration or earlier termination of this Agreement, the license granted hereunder will also terminate, and, without limiting Licensee's obligations under this Agreement, Licensee shall cease using and delete, destroy, or return all copies of the Beta Software and certify in writing to the Licensor that anything it possesses that is related to the Beta Software has been deleted or destroyed. No expiration or termination will affect Licensee's obligation to pay any Fees that may become due before such expiration or termination, nor will it entitle Licensee​ ​to​ ​any​ ​refund.</p></li>
<li><p><u>Survival</u>​. Section 4, this Section 7, and all other Sections of this Agreement that require survival​ ​in​ ​order​ ​for​ ​their​ ​terms​ ​to​ ​be​ ​given​ ​effect,​ ​shall​ ​survive​ ​any​ ​termination​ ​or​ ​expiration​ ​of​ ​this​ ​Agreement.</p></li>
</ol>
<p><strong>8. <u>Miscellaneous</u>​.</strong></p>
<blockquote>
<p>(a) <u>Entire Agreement</u>​. This Agreement, together with any other documents incorporated herein by reference, and all related Exhibits, constitutes the sole and entire agreement between the Parties with respect to the subject matter of this Agreement, and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter.</p>
<p>(b) <u>Notices</u>. Any notice, demand, or other communication that is required or permitted to be given under this Agreement will be sufficient if it is in writing and delivered personally, by email (receipt confirmed), sent by commercial courier service (receipt confirmed), or by United States mail (return receipt requested) to the following:</p>
</blockquote>
<p>If to PillarXP:<br />
PillarXP, LLC<br />
Attention: Thomas J. Trgovac<br />
1144 Kennebec Drive<br />
Chambersburg, PA 17201<br />
Email: <a href="mailto:tomtrgovac@gdcit.com">tomtrgovac@gdcit.com</a></p>
<p>If to Licensee:<br />
[School System]<br />
Attention: [Signee]<br />
[School System Address 1]<br />
[School System City], [School System State] [School System Zip]<br />
Email: [Signee Email]</p>
<blockquote>
<p>(c) <u>Force Majeure</u>​. In no event shall Licensor be liable to Licensee, or be deemed to have breached this Agreement, for any failure or delay in performing its obligations under this Agreement, if and to the extent such failure or delay is caused by any circumstances beyond Licensor's reasonable control, including but not limited to acts of God, flood, fire, earthquake, explosion, war, terrorism, invasion, riot or other civil unrest, strikes, labor stoppages or slowdowns or other industrial disturbances, or passage of law or any action taken by a​ ​governmental​ ​or​ ​public​ ​authority,​ ​including​ ​imposing​ ​an​ ​embargo.</p>
</blockquote>
<ol start="6" type="a">
<li><p><u>Amendment and Modification</u>​. No amendment to or modification of this Agreement is effective unless it is in writing and signed by an authorized representative of each Party.</p></li>
<li><p><u>Waiver</u>. Except as otherwise set forth in this Agreement, no failure to exercise, or delay in exercising, any rights, remedy, or privilege arising from this Agreement will operate as or be construed as a waiver thereof; nor will any single or partial exercise of any right, remedy, or privilege hereunder preclude any​ ​other​ ​or​ ​further​ ​exercise​ ​thereof​ ​or​ ​the​ ​exercise​ ​of​ ​any​ ​other​ ​right,​ ​remedy,​ ​​or​ ​privilege.</p></li>
</ol>
<blockquote>
<p>(e) <u>Severability</u>​. If any provision of this Agreement is declared to be invalid, illegal, or unenforceable in any court of competent jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term or provision of this Agreement or invalidate or render unenforceable such term or provision in any other jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or unenforceable, the Parties hereto shall negotiate in good faith to modify this Agreement so as to affect the original intent of the Parties as closely as possible, in a mutually acceptable manner, in order that the transactions contemplated hereby be consummated as​ ​originally​ ​contemplated​ ​to​ ​the​ ​greatest​ ​extent​ ​possible.</p>
</blockquote>
<ol start="5" type="a">
<li><p><u>Applicable Law</u>. This Agreement is to be governed by the laws of the Commonwealth of Pennsylvania, without regard to principles of conflicts of laws. Any legal action to interpret or enforce the terms of this Agreement, or any documents or exhibits incorporated herein, shall be brought only in State or Federal courts located in the Commonwealth of Pennsylvania with both personal and subject matter jurisdiction over the Parties and the legal action.</p></li>
<li><p><u>Assignment​</u>. Licensee may not assign, sub-assign, delegate, or transfer any of its rights, or delegate any of its obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without the prior written consent of Licensor, which consent will not be unreasonably withheld or delayed. Any purported assignment, transfer, or delegation in violation of this Section is null and void. If a Party grants its consent to assign this Agreement, the permitted assignee must agree that it will be bound by the terms and conditions of this Agreement. If the permitted assignee does not so consent, then any consent to assignment that is granted to the Party seeking assignment will be deemed to be terminated until such time as the permitted assignee agrees to be bound by the terms of this Agreement.</p></li>
<li><p><u>Specific Performance</u>. The Parties hereto agree and acknowledge that money damages may not be an adequate remedy for a breach of certain provisions of this Agreement, and that any Party may, in its sole discretion, apply to any court of competent jurisdiction, in law or equity, for specific performance and/or injunctive relief in order to enforce or prevent any violations of the provisions of this Agreement.</p></li>
<li><p><u>Authority of Person Signing Agreement</u>. By signing this Agreement on behalf of Licensor and/or Licensee, the person signing declares that he/she has the actual authority to bind the Party, and it will be deemed to be confirmation that he/she has actual authority, to bind Licensor and/or Licensee to all the terms and conditions set forth in the Agreement.</p></li>
<li><p><u>Counterparts</u>​. This Agreement may be executed in counterparts, each of which is deemed an original, but all of which together are deemed to be one and the same agreement. Counterparts may be delivered via facsimile, email (including PDF or any legally compliant electronic signature service) or other transmission method, and any counterpart so delivered will​ ​be​ ​deemed​ ​to have been duly​ ​and​ ​validly​ ​delivered​ ​and​ ​be​ ​valid and effective for all purposes.</p></li>
<li><p><u>Drafting</u>. The Parties hereto acknowledge that each Party has participated fully in the negotiation, drafting, and preparation of this Agreement and that each Party has had the opportunity to obtain the full benefit of counsel prior to executing the Agreement. Accordingly, the Agreement shall not be construed more strictly against either Party as the drafter thereof, and no presumption of interpretation shall inure to benefit of either Party.</p></li>
</ol>

`
            ;

        if (contactInfo) {
            let userAgreementFilled = rawUserAgreement;
            userAgreementFilled = userAgreementFilled.replace(/\[school system\]/gi, contactInfo?.schoolDistrictName ?? "");
            userAgreementFilled = userAgreementFilled.replace(/\[signee\]/gi, (contactInfo?.firstName ?? "") + " " + (contactInfo?.lastName ?? ""));
            userAgreementFilled = userAgreementFilled.replace(/\[School System address 1\]/gi, contactInfo?.address1 ?? "");
            userAgreementFilled = userAgreementFilled.replace(/\[School System address 2\]/gi, contactInfo?.address2 ?? "");
            userAgreementFilled = userAgreementFilled.replace(/\[School System city\]/gi, contactInfo?.city ?? "")
            userAgreementFilled = userAgreementFilled.replace(/\[School System state\]/gi, contactInfo?.state ?? "")
            userAgreementFilled = userAgreementFilled.replace(/\[School System zip\]/gi, contactInfo?.zipCode ?? "")
            userAgreementFilled = userAgreementFilled.replace(/\[signee email\]/gi, contactInfo?.emailAddress ?? "")

            setUserAgreement(userAgreementFilled);
        }

    }, [contactInfo])


    return (
        <div>
            <Box dangerouslySetInnerHTML={{ __html: userAgreement }}></Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                    PillarXP, LLC
                </Box>
                <Box sx={{ flex: 1, textAlign: 'left' }}>
                    {contactInfo?.schoolDistrictName ?? ""}
                </Box>
            </Box>

        </div>

    )
}

