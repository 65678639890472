import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import StepFour from './StepFour';
import StepFive from './StepFive';
import { StepsEnum } from '../../../utility/enums';
import ContentWrapper from '../../Common/ContentWrapper';
import StepsIndicator from '../../Common/StepIndicator/StepsIndicator';
import { useAddSurveyTemplateMutation, useGetSurveyTemplateDetailsMutation, useUpdateSurveyTemplateMutation } from '../surveyTemplateManagementSlice';
import { SurveyTemplateStatusEnum } from '../../../utility/enums';

const stepsArray = [
    { id: StepsEnum.ONE, description: 'Setup' },
    { id: StepsEnum.TWO, description: 'Campaigns, Pillars, and Benefits' },
    { id: StepsEnum.THREE, description: 'Question Builder' },
    { id: StepsEnum.FOUR, description: 'Scheduling & Distribution' },
    { id: StepsEnum.FIVE, description: 'Review' }
];

export default function SurveyManagementWizard() {
    const [currentStep, setCurrentStep] = useState(StepsEnum.ONE);
    const [stepOneTitle, setStepOneTitle] = useState('');
    const [stepTwoCampaign, setStepTwoCampaign] = useState();
    const [campaignStartDate, setCampaignStartDate] = useState();
    const [campaignEndDate, setCampaignEndDate] = useState();
    const [stepTwoPillars, setStepTwoPillars] = useState([]);
    const [stepTwoBenefits, setStepTwoBenefits] = useState([]);
    const [stepThreeQuestions, setStepThreeQuestions] = useState([]);
    const [stepFourDistroSchedule, setStepFourDistroSchedule] = useState([]);

    const originalTitleRef = useRef();

    const navigate = useNavigate();
    const { id, copiedTemplateId } = useParams()

    const [getSurveyTemplateDetailsQuery, { isLoading }] = useGetSurveyTemplateDetailsMutation();
    const [addSurveyTemplate, { isLoading: isAdding }] = useAddSurveyTemplateMutation();
    const [updateSurveyTemplate, { isLoading: isUpdating }] = useUpdateSurveyTemplateMutation();

    const fetchSurveyTemplateDetails = useCallback((templateId, isTemplate = false) => {
        getSurveyTemplateDetailsQuery(templateId)
            .unwrap()
            .then(res => {
                setStepTwoCampaign(res.campaignId);
                setStepTwoPillars(res.pillarIds);
                setStepTwoBenefits(res.benefitIds);
                setStepThreeQuestions(res.surveyQuestions);
                setCampaignStartDate(res.campaignStartDate);
                setCampaignEndDate(res.campaignEndDate);
                const mappedDistroSchedule = {
                    roleIds: res.roleIds,
                    cronSchedule: res.cronSchedule,
                    minutesToComplete: res.minutesToComplete,
                    sampleSizeId: res.sampleSizeId,
                    rotationId: res.rotationId,
                    schoolBuildingIds: res.schoolBuildingIds
                };
                if (res.surveyTemplateStatusId !== SurveyTemplateStatusEnum.UPCOMING) {
                    navigate('/*')
                }
                if (!isTemplate) {
                    originalTitleRef.current = res.title;
                    setStepOneTitle(res.title);
                    mappedDistroSchedule.startDate = dayjs(res.startDate);
                    mappedDistroSchedule.endDate = dayjs(res.endDate);
                } else {
                    setStepOneTitle(`Copy of ${res.title}`);
                    toast.success('Survey template copied successfully');
                }
                setStepFourDistroSchedule(mappedDistroSchedule);
            })
            .catch(err => {
                console.error(err);
                toast.error(err.message);
            });
    }, [getSurveyTemplateDetailsQuery]);

    useEffect(() => {
        if (id && id !== 'new' && id !== copiedTemplateId) {
            fetchSurveyTemplateDetails(id);
        } else if (copiedTemplateId) {
            fetchSurveyTemplateDetails(parseInt(copiedTemplateId), true);
        }
    }, [id, copiedTemplateId, fetchSurveyTemplateDetails]);

    const cancel = () => {
        navigate('/survey-template-management');
    };

    const buildDto = () => {
        return {
            title: stepOneTitle,
            campaignId: stepTwoCampaign,
            pillarIds: stepTwoPillars,
            benefitIds: stepTwoBenefits,
            surveyQuestions: stepThreeQuestions,
            schoolBuildingIds: stepFourDistroSchedule.schoolBuildingIds,
            roleIds: stepFourDistroSchedule.roleIds,
            minutesToComplete: parseInt(stepFourDistroSchedule.minutesToComplete),
            sampleSizeId: stepFourDistroSchedule.sampleSizeId,
            rotationId: stepFourDistroSchedule.rotationId,
            cronSchedule: stepFourDistroSchedule.cronSchedule,
            startDate: stepFourDistroSchedule.startDate && stepFourDistroSchedule.startDate.toISOString(),
            endDate: stepFourDistroSchedule.endDate && stepFourDistroSchedule.endDate.toISOString(),
        };
    };

    const submitWizard = () => {
        const dto = buildDto();

        if (id === 'new' || !id) {
            addSurveyTemplate(dto)
                .unwrap()
                .then(() => {
                    toast.success('Survey template added successfully');
                    navigate('/survey-template-management');
                })
                .catch(err => {
                    console.error(err);
                    toast.error(err.data.message);
                });
        } else {
            const dtoWithPk = {
                ...dto,
                id: parseInt(id)
            };

            updateSurveyTemplate(dtoWithPk)
                .unwrap()
                .then(() => {
                    toast.success('Survey template updated successfully');
                    navigate('/survey-template-management');
                })
                .catch(err => {
                    console.error(err);
                    toast.error(err.data.message);
                });
        }
    };

    const prevStep = (prev) => {
        setCurrentStep(prev);
    };

    const checkLastSavedStep = () => {
        const savedSteps = [
            !!stepOneTitle, !!stepTwoCampaign && stepTwoPillars.length > 0 && stepTwoBenefits.length > 0,
            stepThreeQuestions.length > 0,
            (stepFourDistroSchedule.schoolBuildingIds?.length > 0 && stepFourDistroSchedule.roleIds?.length > 0),
            (stepFourDistroSchedule.schoolBuildingIds?.length > 0 && stepFourDistroSchedule.roleIds?.length > 0)
        ];

        for (let i = savedSteps.length - 1; i >= 0; i--) {
            if (savedSteps[i]) {
                return i;
            }
        }
        return 0;
    };

    let view = null;

    switch (currentStep) {
        case StepsEnum.ONE:
            view = (
                <StepOne
                    title={stepOneTitle}
                    setTitle={setStepOneTitle}
                    isEdit={id}
                    originalTitle={originalTitleRef.current}
                    cancel={cancel}
                    nextStep={() => setCurrentStep(StepsEnum.TWO)}
                    isLoading={isLoading}
                />
            );
            break;
        case StepsEnum.TWO:
            view = (
                <StepTwo
                    title={stepOneTitle}
                    setSelectedCampaign={setStepTwoCampaign}
                    setCampaignStartDate={setCampaignStartDate}
                    setCampaignEndDate={setCampaignEndDate}
                    setSelectedPillars={setStepTwoPillars}
                    setSelectedBenefits={setStepTwoBenefits}
                    selectedCampaign={stepTwoCampaign}
                    selectedPillars={stepTwoPillars}
                    selectedBenefits={stepTwoBenefits}
                    prevStep={() => prevStep(StepsEnum.ONE)}
                    nextStep={() => setCurrentStep(StepsEnum.THREE)}
                />
            );
            break;
        case StepsEnum.THREE:
            view = (
                <StepThree
                    title={stepOneTitle}
                    questions={stepThreeQuestions}
                    setQuestions={setStepThreeQuestions}
                    prevStep={() => prevStep(StepsEnum.TWO)}
                    nextStep={() => setCurrentStep(StepsEnum.FOUR)}
                />
            );
            break;
        case StepsEnum.FOUR:
            view = (
                <StepFour
                    title={stepOneTitle}
                    distributionSchedule={stepFourDistroSchedule}
                    setDistributionSchedule={setStepFourDistroSchedule}
                    campaignId={stepTwoCampaign}
                    campaignStartDate={campaignStartDate}
                    campaignEndDate={campaignEndDate}
                    prevStep={() => prevStep(StepsEnum.THREE)}
                    nextStep={() => setCurrentStep(StepsEnum.FIVE)}
                />
            );
            break;
        case StepsEnum.FIVE:
            view = (
                <StepFive
                    details={buildDto()}
                    prevStep={() => prevStep(StepsEnum.FOUR)}
                    nextStep={submitWizard}
                    isSaving={isAdding || isUpdating}
                />
            );
            break;
        default:
            view = <Typography>Invalid Step</Typography>
            break;
    };

    return (
        <ContentWrapper title="Survey Template Management">
            <StepsIndicator
                steps={stepsArray}
                currentStep={currentStep}
                changeStep={(s) => setCurrentStep(s)}
                lastSavedStep={checkLastSavedStep()}
            />
            <Box
                style={{ display: 'flex', justifyContent: 'center', marginTop: '50px', width: '100%' }}
            >
                {view}
            </Box>
        </ContentWrapper>
    );
}