import { useState } from 'react';
import { Box, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import { Add } from '@mui/icons-material';
import { SurveyQuestionTypeEnum } from '../../../../utility/enums';
import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import QuestionAnswerOption from './QuestionAnswerOption';
import { int32RandomUUID } from '../../../../utility/uuid';

export default function QuestionTypeForm({ questionTypeId, ordinal, surveyQuestionOptions, formErrors, setFormErrors, onOptionsChange }) {
    const [option, setOption] = useState('');

    const handleAddOption = () => {
        if (!option || !option.trim()) {
            setFormErrors(prev => ({ ...prev, 'optionsArray': 'Option text is required' }));
        } else {
            const newOptions = [
                ...surveyQuestionOptions,
                {
                    id: int32RandomUUID(), // Must be given a unique id for the sortable context
                    title: option.trim(),
                    ordinal: surveyQuestionOptions.length + 1
                }];
            onOptionsChange(newOptions);
            setOption('');
            setFormErrors(prev => ({ ...prev, 'optionsArray': null }));
        }
    };

    const handleOptionBlur = () => {
        if (option.trim()) {
            setFormErrors(prev => ({ ...prev, 'optionsArray': null }));
        }
    };

    const handleDeleteOption = (index) => {
        const newOptions = surveyQuestionOptions.filter((_, i) => i !== index);
        onOptionsChange(newOptions);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) return;

        if (active.id !== over.id) {
            const oldIndex = surveyQuestionOptions.findIndex((q) => q.id === active.id);
            const newIndex = surveyQuestionOptions.findIndex((q) => q.id === over.id);

            const movedOptions = arrayMove(surveyQuestionOptions, oldIndex, newIndex).map((o, index) => ({
                ...o,
                ordinal: index + 1
            }));

            onOptionsChange(movedOptions);
        }
    };

    switch (questionTypeId) {
        case SurveyQuestionTypeEnum.SHORT_ANSWER:
            return (
                <Box sx={{ pr: '55px' }}>
                    <TextField
                        id={`${ordinal}-disabled-text-field`}
                        type="text"
                        size="small"
                        fullWidth
                        disabled
                        value={''}
                    />
                </Box>
            );
        case SurveyQuestionTypeEnum.YES_OR_NO:
            return (
                <RadioGroup name="radio-buttons-group" row>
                    <FormControlLabel disabled value="1" control={<Radio />} label="Yes" />
                    <FormControlLabel disabled value="0" control={<Radio />} label="No" />
                </RadioGroup>
            );
        case SurveyQuestionTypeEnum.CHECKBOX_CHOICES:
        case SurveyQuestionTypeEnum.MULTIPLE_CHOICE:
            return (
                <>
                    <RadioGroup name='radio-buttons-group'>
                        <DndContext
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={surveyQuestionOptions}
                                strategy={verticalListSortingStrategy}
                            >
                                {surveyQuestionOptions.map((option, i) => (
                                    <QuestionAnswerOption
                                        key={option.id}
                                        id={option.id}
                                        option={option}
                                        index={i}
                                        ordinal={ordinal}
                                        questionTypeId={questionTypeId}
                                        onDelete={handleDeleteOption}
                                        SurveyQuestionTypeEnum={SurveyQuestionTypeEnum}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </RadioGroup>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '15px' }}>
                        <TextField
                            id={`${ordinal}-option-input`}
                            label="Add option"
                            variant='outlined'
                            type='text'
                            size='small'
                            fullWidth
                            required
                            value={option}
                            onBlur={handleOptionBlur}
                            error={!!formErrors?.['optionsArray']}
                            helperText={formErrors?.['optionsArray']}
                            onChange={e => setOption(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleAddOption();
                                }
                            }}
                        />
                        <IconButton
                            color='secondary'
                            onClick={handleAddOption}
                        >
                            <Add />
                        </IconButton>
                    </Box>
                </>
            );
        default:
            return null;
    }
}