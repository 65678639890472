import { useState } from 'react';
import { TextField, Autocomplete, Card, Box, Typography, Stack, Skeleton } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useGetCoordinatorsQuery, useLazyIsCampaignNameInUseQuery } from '../campaignWizardSlice';
import WizardButtonGroup from '../../../Common/Buttons/WizardButtonGroup';
import { toast } from 'react-toastify';

export default function CampaignWizardStepOne({ details, setDetails, districtCampaigns, handleStepComplete, cancel, isEdit, isLoading }) {
    const [formErrors, setFormErrors] = useState({});

    const { data: coordinatorOptions = [], isLoading: isLoadingCoordinators } = useGetCoordinatorsQuery();
    const [isNameInUse] = useLazyIsCampaignNameInUseQuery();

    const isCampaignNameInUse = (name) => districtCampaigns.some(c => c.name.toLowerCase() === name.toLowerCase());

    const isFormValid = () => {
        const errors = {};

        if (details.name && !isEdit)
            errors['name'] = isCampaignNameInUse(details.name) ? "Campaign name is already in use" : null;
        else if (!details.name)
            errors['name'] = "Name is required";

        errors['startDate'] = !details.startDate ? "Start date is required" : null;
        errors['endDate'] = !details.endDate ? "End date is required" : null;
        errors['coordinators'] = details.coordinators.length === 0 ? "At least one coordinator is required" : null;

        setFormErrors(errors);

        for (let prop in errors)
            if (errors.hasOwnProperty(prop) && errors[prop] != null)
                return false;

        return true;
    };

    const handleNext = async () => {
        if (!isFormValid())
            return;

        // Additional check for duplicate names (looks at non-active campaigns as well)
        if (!isEdit) {
            try {
                const errors = { ...formErrors };
                const response = await isNameInUse(details.name).unwrap();

                if (response?.error)
                    throw new Error("An error occurred when checking for duplicate names.");

                if (response.isDuplicate) {
                    errors['name'] = "Campaign name is already in use";
                    setFormErrors(errors);
                    return;
                }
            } catch (error) {
                toast.error(error);
                return;
            }
        }

        handleStepComplete(details);
    };

    const reset = () => {
        setDetails({
            name: '',
            startDate: null,
            endDate: null,
            coordinators: [],
            objective: '',
        });
        setFormErrors({});
    };

    const handleConfirmCancel = () => {
        reset();
        cancel();
    };

    const DetailsFormSkeleton = () => (
        <Stack spacing={2}>
            <Skeleton variant='rectangular' width='100%' height={42} />
            <Box sx={{ display: 'flex', gap: '20px' }}>
                <Skeleton variant='rectangular' width='100%' height={42} />
                <Skeleton variant='rectangular' width='100%' height={42} />
            </Box>
            <Skeleton variant='rectangular' width='100%' height={42} />
            <Skeleton variant='rectangular' width='100%' height={111} />
            <Skeleton variant='rectangular' width='100%' height={42} />
        </Stack>
    );

    return (
        <Card
            sx={{
                flexBasis: '75%',
                maxWidth: '510px',
                padding: '25px',
                mb: '25px',
                backgroundColor: (theme) => theme.palette.grey[100]
            }}
        >
            <Stack spacing={2}>
                <Typography>
                    {isEdit ?
                        <span>
                            <span style={{ fontWeight: 'bold' }}>Edit Campaign Details. </span>Make changes to the campaign details below.
                        </span>
                        :
                        <span>
                            <span style={{ fontWeight: 'bold' }}>Let&apos;s Get Started. </span>Please enter campaign details to begin creating a new campaign.
                        </span>
                    }
                </Typography>
                {isLoading || isLoadingCoordinators ? <DetailsFormSkeleton /> :
                    <>
                        <TextField
                            fullWidth
                            label="Name"
                            value={details.name}
                            onChange={(e) => setDetails({ ...details, name: e.target.value })}
                            required
                            size='small'
                            inputProps={{ maxLength: 100 }}
                            error={!!formErrors['name']}
                            helperText={formErrors['name']}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white'
                                }
                            }}
                        />
                        <Box sx={{ display: 'flex', gap: '20px' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start Date"
                                    value={details.startDate}
                                    maxDate={details.endDate}
                                    onChange={(date) => setDetails({ ...details, startDate: date })}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            error: !!formErrors['startDate'],
                                            helperText: formErrors['startDate'],
                                            size: 'small',
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: 'white'
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End Date"
                                    value={details.endDate}
                                    minDate={details.startDate}
                                    onChange={(date) => setDetails({ ...details, endDate: date })}
                                    required
                                    error={!!formErrors['endDate']}
                                    helperText={formErrors['endDate']}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                            error: !!formErrors['endDate'],
                                            helperText: formErrors['endDate'],
                                            size: 'small',
                                        },
                                    }}
                                    sx={{
                                        '& .MuiInputBase-root': {
                                            backgroundColor: 'white'
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </Box>
                        <Autocomplete
                            id='coordinator-select'
                            multiple
                            options={coordinatorOptions}
                            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                            value={details.coordinators}
                            onChange={(_e, newValue) => setDetails({ ...details, coordinators: newValue })}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disableCloseOnSelect
                            renderInput={params => (
                                <TextField {...params}
                                    label="Coordinator(s)"
                                    required
                                    size='small'
                                    error={!!formErrors['coordinators']}
                                    helperText={formErrors['coordinators']}
                                />
                            )}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white'
                                }
                            }} />
                        <TextField
                            fullWidth
                            multiline
                            size='small'
                            rows={4}
                            label="Objective"
                            value={details.objective}
                            onChange={(e) => setDetails({ ...details, objective: e.target.value })}
                            inputProps={{ maxLength: 500 }}
                            sx={{
                                '& .MuiInputBase-root': {
                                    backgroundColor: 'white'
                                }
                            }} />
                    </>
                }
                <WizardButtonGroup
                    fullWidth
                    backText="Cancel"
                    forwardClick={handleNext}
                    backClick={handleConfirmCancel}
                    primaryDisabled={isLoading || isLoadingCoordinators}
                />
            </Stack>
        </Card>
    );
};